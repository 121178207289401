import React from 'react'
import { ApiError } from '../api/common'
import ErrorMessage from './error-message'

const ApiErrorMessage: React.FC<{ error: ApiError }> = (props) => (
  <ErrorMessage>
    <ul>
      {Object.entries(props.error.details).map(([name, details]) => (
        <ApiErrorField
          key={name}
          name={name}
          details={details}
        />
      ))}
    </ul>
  </ErrorMessage>
)

export default ApiErrorMessage

const ApiErrorField: React.FC<{ name: string, details: string[] }> = (props) => (
  <li>
    {props.name}
    <ul>
      {props.details.map(message => (
        <ApiErrorFieldMessage key={message} message={message} />
      ))}
    </ul>
  </li>
)

const ApiErrorFieldMessage: React.FC<{ message: string }> = (props) => (
  <li>{props.message}</li>
)
