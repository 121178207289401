import React from 'react'
import { Field, isInvalid } from '../../shared/form'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import DialogContentText from '@mui/material/DialogContentText'
import Box from '@mui/material/Box'
import Translate, { translateString } from '../../components/translate'
import { Translations } from '../../api/language'
import { useTheme } from '@mui/material/styles'

export type Props = {
  fullName: Field<string>
  email: Field<string>
  disabled: boolean
  translations: Translations
  onNameChange: (value: string) => void
  onEmailChange: (value: string) => void
}

const UserForm: React.FC<Props> = (props) => {
  const theme = useTheme()

  return (
    <>
      <DialogContentText sx={{ marginBottom: theme.spacing(2) }}>
        <Translate>ADD_USER_HELPER_TEXT</Translate>
      </DialogContentText>

      <FormControl fullWidth>
        <TextField
          variant='filled'
          id='user-email'
          required
          type='email'
          autoComplete='off'
          label={<Translate>EMAIL</Translate>}
          disabled={props.disabled || props.email.readonly}
          value={props.email.value}
          onChange={(event) => props.onEmailChange(event.target.value)}
          {...fieldValidationProps(props.email, props.translations)}
        />
      </FormControl>

      <Box mt={3}>
        <FormControl fullWidth>
          <TextField
            variant='filled'
            label={<Translate>FULLNAME</Translate>}
            id='user-name'
            autoComplete='off'
            disabled={props.disabled || props.fullName.readonly}
            value={props.fullName.value}
            inputProps={{ maxLength: 255 }}
            onChange={(event) => props.onNameChange(event.target.value)}
            {...fieldValidationProps(props.fullName, props.translations)}
          />
        </FormControl>
      </Box>
    </>
  )
}

export default UserForm

function fieldValidationProps<T> (
  field: Field<T>,
  translations: Translations
): {
  error: boolean
  helperText: false | string
} {
  if (!field.pristine && isInvalid(field)) {
    return {
      error: true,
      helperText: translateString(translations, field.error)
    }
  }

  return {
    error: false,
    helperText: false
  }
}
