import * as decode from 'decoders'
import { handleJsonResponse, handleResponse } from './common'

export type UserModel = {
  id: string
  fullName: null | string
  email: string
  userRoles: UserRole[]
}

type UserRole = {
  name: string
}

export type CreateUserModel = {
  email: string
  fullName: null | string
}

export type SetUserRolesModel = {
  roleNames: string[]
}

export const getAll = async (token: string): Promise<UserModel[]> => {
  const response = await fetch('/api/users', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (!response.ok) throw new Error(response.statusText)

  const json = await response.json()
  const users = decode.guard(decode.array(userDecoder))(json)

  return users.sort((a, b) => (a.fullName ?? a.email).localeCompare(b.fullName ?? b.email))
}

export const create = async (token: string, user: CreateUserModel): Promise<UserModel> => {
  const response = await fetch('/api/users', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(user)
  })

  return handleJsonResponse(response, decode.guard(userDecoder))
}

export const remove = async (token: string, userId: string): Promise<void> => {
  const response = await fetch(`/api/users/${userId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (!response.ok) throw Error(response.statusText)
}

const userRoleDecoder: decode.Decoder<UserRole> = decode.object({
  name: decode.string
})

const userDecoder: decode.Decoder<UserModel> = decode.object({
  id: decode.string,
  fullName: decode.nullable(decode.string),
  email: decode.string,
  userRoles: decode.array(userRoleDecoder)
})

export const updateRoles = async (
  token: string,
  userId: string,
  roleUsers: SetUserRolesModel
): Promise<void> => {
  const response = await fetch(`/api/users/${userId}/roles`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(roleUsers)
  })

  await handleResponse(response)
}

export interface ChangePasswordModel {
  readonly newPassword: string
  readonly newPasswordConfirmation: string
}

export const changePassword = async (
  token: string,
  userId: string,
  model: ChangePasswordModel
): Promise<void> => {
  const response = await fetch(`/api/users/${userId}/password`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(model)
  })

  await handleResponse(response)
}

export interface UpdateUserFullNameModel {
  readonly fullName: null | string
}

export const updateFullName = async (
  token: string,
  userId: string,
  model: UpdateUserFullNameModel
): Promise<void> => {
  const response = await fetch(`/api/users/${userId}/full-name`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(model)
  })

  await handleResponse(response)
}
