import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import ArrowDropdown from '@mui/icons-material/ArrowDropDown'
import TranslationsContext from '../../contexts/translations'
import { RemoteData, RemoteDataType } from '../../shared/remote-data'
import { Culture } from '../../api/language'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorMessage from '../error-message'
import Translate from '../translate'
import { useTheme } from '@mui/material/styles'

// COMPONENT

export type Props = {
  cultures: RemoteData<Error, Culture[]>
  onLoad: () => void
  onSelect: (culture: Culture) => void
}

const CultureSelect: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null)
  const translations = React.useContext(TranslationsContext)
  const theme = useTheme()

  const handleOpen = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
    if (props.cultures.type === RemoteDataType.NotAsked) props.onLoad()
  }

  const handleClose = () => setAnchorEl(null)

  return (
    <>
      <Button
        data-testid='culture-select-button'
        aria-controls='culture-select'
        aria-haspopup='true'
        onClick={handleOpen}
        sx={{
          color: 'inherit',
          fontWeight: 'normal',
          textTransform: 'none'
        }}
      >
        {translations.culture}
        <ArrowDropdown />
      </Button>
      <Menu
        id='culture-select'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {(() => {
          switch (props.cultures.type) {
            case RemoteDataType.NotAsked:
            case RemoteDataType.Loading:
              return (
                <MenuItem>
                  <CircularProgress />
                </MenuItem>
              )

            case RemoteDataType.Failed:
              return (
                <MenuItem>
                  <ErrorMessage>
                    <Translate>FETCH_GENERIC_ERROR</Translate>
                  </ErrorMessage>
                </MenuItem>
              )

            case RemoteDataType.Succeeded:
              return props.cultures.data.map(culture => (
                <MenuItem
                  key={culture.name}
                  onClick={() => props.onSelect(culture)}
                  sx={{
                    color: theme.palette.grey[800],
                    fontSize: theme.typography.body2.fontSize
                  }}
                >
                  {culture.displayName}
                </MenuItem>
              ))
          }
        })()}
      </Menu>
    </>
  )
}

export default CultureSelect
