import React from 'react'
import { StoreContext, ChangePasswordType, changePasswordCanceled, changePasswordNewPasswordUpdated, changePasswordNewPasswordConfirmationUpdated, changePasswordSaveRequested, changePasswordSaveSucceeded, changePasswordSaveFailed } from './store'
import SessionContext from '../../contexts/session'
import TranslationsContext from '../../contexts/translations'
import Translate, { translateString } from '../../components/translate'
import remoteData from '../../shared/remote-data'
import ActionDialog from '../../components/action-dialog'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import * as Form from '../../shared/form'
import DialogContentText from '@mui/material/DialogContentText'
import * as User from '../../api/user'
import { ChangePasswordStarted, isValid } from './store/change-password'
import { Translations } from '../../api/language'
import ApiResultErrorMessage from '../../components/api-result-error-message'
import { styled, Theme } from '@mui/material/styles'

const ChangePassword: React.FC = () => {
  const { state } = React.useContext(StoreContext)
  if (state.changePassword.type === ChangePasswordType.NotStarted) return null
  return <ChangePasswordDialog {...state.changePassword} />
}

const ChangePasswordDialog: React.FC<ChangePasswordStarted> = ({ user, form, save }) => {
  const { dispatch } = React.useContext(StoreContext)
  const session = React.useContext(SessionContext)
  const translations = React.useContext(TranslationsContext)

  React.useEffect(() => {
    if (!remoteData.isLoading(save)) return

    let isMounted = true

    User.changePassword(
      session.token,
      user.id,
      {
        newPassword: form.newPassword.value,
        newPasswordConfirmation: form.newPasswordConfirmation.value
      }
    )
      .then(
        () => changePasswordSaveSucceeded({
          id: Math.random(),
          message: translateString(translations, 'PASSWORD_CHANGED_SUCCESSFULLY')
        }),
        changePasswordSaveFailed
      )
      .then(action => isMounted && dispatch(action))

    return () => { isMounted = false }
  }, [
    save.type
  ])

  const handleSave = () => {
    if (!isValid(form)) return false
    dispatch(changePasswordSaveRequested())
  }

  return (
    <ActionDialog
      title='CHANGE_PASSWORD'
      open={true}
      saving={remoteData.isLoading(save)}
      readOnly={false}
      onSave={handleSave}
      onCancel={() => dispatch(changePasswordCanceled())}
    >
      <DialogContentText sx={{ maxWidth: '32rem' }}>
        <Translate>CHANGE_PASSWORD_HELPER_TEXT</Translate>
      </DialogContentText>
      <StyledFormControl fullWidth>
        <TextField
          variant='filled'
          label={<Translate>NEW_PASSWORD</Translate>}
          id='new-password'
          type='password'
          required
          autoComplete='off'
          disabled={remoteData.isLoading(save)}
          value={form.newPassword.value}
          {...validationProps(form.newPassword, translations)}
          onChange={(event) => dispatch(changePasswordNewPasswordUpdated(event.target.value))}
        />
      </StyledFormControl>
      <StyledFormControl fullWidth>
        <TextField
          variant='filled'
          label={<Translate>NEW_PASSWORD_CONFIRMATION</Translate>}
          id='new-password-confirmation'
          type='password'
          required
          autoComplete='off'
          disabled={remoteData.isLoading(save)}
          value={form.newPasswordConfirmation.value}
          {...validationProps(form.newPasswordConfirmation, translations)}
          onChange={(event) => dispatch(changePasswordNewPasswordConfirmationUpdated(event.target.value))}
        />
      </StyledFormControl>
      <ApiResultErrorMessage
        result={save}
        fallbackMessage={<Translate>CHANGE_PASSWORD_GENERIC_ERROR</Translate>}
      />
    </ActionDialog>
  )
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(3)
}))

function validationProps <T> (field: Form.Field<T>, translations: Translations): { error: boolean, helperText: null | string } {
  if (!field.pristine && Form.isInvalid(field)) {
    return {
      error: true,
      helperText: translateString(translations, field.error)
    }
  }

  return {
    error: false,
    helperText: null
  }
}

export default ChangePassword
