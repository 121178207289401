import React from 'react'
import { UpdateRoleModel, RoleModel } from '../../../api/role'
import { Form, initValid, initReadonly, toValid } from '../../../shared/form'
import remoteData, { RemoteData } from '../../../shared/remote-data'
import { Notification } from '../../../components/notifications'

// STATE

export type State =
  | { type: RoleEditingType.NotStarted }
  | {
      type: RoleEditingType.InProgress
      role: RoleModel
      form: Form<UpdateRoleModel>
      save: RemoteData<Error, {}>
    }

export enum RoleEditingType {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress'
}

export const initialState: State = {
  type: RoleEditingType.NotStarted
}

// ACTION

export const EDITING_REQUESTED = 'EDITING_REQUESTED'
export const EDITING_CANCELED = 'EDITING_CANCELED'
export const EDITING_DESCRIPTION_UPDATE = 'EDITING_DESCRIPTION_UPDATE '
export const EDITING_SAVE_REQUESTED = 'EDITING_SAVE_REQUESTED'
export const EDITING_SAVE_FAILED = 'EDITING_SAVE_FAILED'
export const EDITING_SAVE_SUCCEEDED = 'EDITING_SAVE_SUCCEEDED'

export type Action =
  | { type: typeof EDITING_REQUESTED, payload: RoleModel }
  | { type: typeof EDITING_CANCELED }
  | { type: typeof EDITING_DESCRIPTION_UPDATE, payload: string }
  | { type: typeof EDITING_SAVE_REQUESTED }
  | { type: typeof EDITING_SAVE_FAILED, payload: Error }
  | { type: typeof EDITING_SAVE_SUCCEEDED, payload: Notification }

export const editingRequested = (role: RoleModel): Action => ({
  type: EDITING_REQUESTED,
  payload: role
})

export const editingCanceled = (): Action => ({
  type: EDITING_CANCELED
})

export const editingDescriptionUpdate = (description: string): Action => ({
  type: EDITING_DESCRIPTION_UPDATE,
  payload: description
})

export const editingSaveRequested = (): Action => ({
  type: EDITING_SAVE_REQUESTED
})

export const editingSaveFailed = (error: Error): Action => ({
  type: EDITING_SAVE_FAILED,
  payload: error
})

export const editingSaveSucceeded = (notification: Notification): Action => ({
  type: EDITING_SAVE_SUCCEEDED,
  payload: notification
})

// REDUCER

export const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case EDITING_REQUESTED:
      return {
        ...state,
        type: RoleEditingType.InProgress,
        role: action.payload,
        form: {
          name: initReadonly(action.payload.name),
          description: initValid(action.payload.description)
        },
        save: remoteData.notAsked()
      }

    case EDITING_SAVE_SUCCEEDED:
    case EDITING_CANCELED:
      return {
        type: RoleEditingType.NotStarted
      }

    case EDITING_DESCRIPTION_UPDATE:
      return updateForm(state, form => ({
        ...form,
        description: toValid(form.description, action.payload)
      }))

    case EDITING_SAVE_REQUESTED:
      return updateSave(state, () => remoteData.loading())

    case EDITING_SAVE_FAILED:
      return updateSave(state, () => remoteData.failed(action.payload))

    default:
      return state
  }
}

const updateForm = (
  state: State,
  update: (form: Form<UpdateRoleModel>) => Form<UpdateRoleModel>
): State => {
  switch (state.type) {
    case RoleEditingType.InProgress:
      return {
        ...state,
        form: update(state.form)
      }

    default:
      return state
  }
}

const updateSave = (
  state: State,
  update: (save: RemoteData<Error, {}>) => RemoteData<Error, {}>
): State => {
  switch (state.type) {
    case RoleEditingType.InProgress:
      return {
        ...state,
        save: update(state.save)
      }

    default:
      return state
  }
}
