import React from 'react'
import Divider from '@mui/material/Divider'
import remoteData, { RemoteData, RemoteDataType } from '../shared/remote-data'
import Translate from './translate'
import ActionDialog from './action-dialog'
import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'
import LinearProgress from '@mui/material/LinearProgress'
import List from '@mui/material/List'
import ErrorMessage from './error-message'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Checkbox from '@mui/material/Checkbox'
import { ApiError } from '../api/common'
import ApiErrorMessage from './api-error-message'
import { useTheme } from '@mui/material/styles'

type ManageRelationsProps = {
  title: string
  description: string
  relationTitle: string
  open: boolean
  options: RemoteData<Error, OptionModel[]>
  saveResult: RemoteData<Error, {}>
  readOnly: boolean
  onSave: () => void
  onCancel: () => void
  onChange: (id: string, checked: boolean) => void
}

const ManageRelations: React.FC<ManageRelationsProps> = (props) => {
  const saving = remoteData.isLoading(props.saveResult)
  return (
    <ActionDialog
      title={props.title}
      subtitle={props.description}
      open={props.open}
      saving={saving}
      readOnly={props.readOnly}
      onSave={props.onSave}
      onCancel={props.onCancel}
    >
      <FormGroup>
        <Box fontWeight='bold' px={2} py={1}>
          <Translate>{props.relationTitle}</Translate>
        </Box>
        {(() => {
          switch (props.options.type) {
            case RemoteDataType.NotAsked:
            case RemoteDataType.Loading:
              return <LinearProgress />

            case RemoteDataType.Succeeded:
              return (
                <>
                  <Box overflow='auto' maxHeight='230px'>
                    <List>
                      {props.options.data && props.options.data.map(option => (
                        <OptionItem
                          key={option.id}
                          option={option}
                          selected={option.selected}
                          disabled={props.readOnly || saving}
                          onChange={props.onChange}
                        />
                      ))}
                    </List>
                  </Box>
                  <Divider />
                </>
              )

            case RemoteDataType.Failed:
              return (
                <ErrorMessage>
                  <Translate>FETCH_GENERIC_ERROR</Translate>
                </ErrorMessage>
              )
          }
        })()}
        {props.saveResult && (
          <UpdateFailedHandler result={props.saveResult} />
        )}
      </FormGroup>
    </ActionDialog>
  )
}

export default ManageRelations

const OptionItem: React.FC<{
  option: OptionModel,
  selected: boolean,
  disabled: boolean,
  onChange: (id: string, checked: boolean) => void
}> = ({ option, selected, disabled, onChange }) => {
  const theme = useTheme()
  const labelId = `manage-relation-option-${option.id}`

  return (
    <>
      <Divider />
      <ListItem
        dense
        button
        disabled={disabled}
        onClick={() => onChange(
          option.id,
          !selected
        )}
        sx={{
          paddingTop: theme.spacing(0.375),
          paddingBottom: theme.spacing(0.375)
        }}
      >
        <ListItemText
          id={labelId}
          primary={option.name}
        />
        <ListItemIcon sx={{ minWidth: 'initial' }}>
          <Checkbox
            edge='end'
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
            checked={selected}
            onChange={event => onChange(
              option.id,
              event.target.checked
            )}
          />
        </ListItemIcon>
      </ListItem>
    </>
  )
}

export type OptionModel = {
  id: string
  name: string
  selected: boolean
}

const UpdateFailedHandler: React.FC<{ result: RemoteData<Error, {}> }> = ({ result }) => {
  if (!remoteData.isFailed(result)) return null

  return (
    <Box mt={2}>
      {result.error instanceof ApiError
        ? (<ApiErrorMessage error={result.error} />)
        : (<ErrorMessage><Translate>UPDATE_GENERIC_ERROR</Translate></ErrorMessage>)
      }
    </Box>
  )
}
