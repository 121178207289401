import React from 'react'
import * as Form from '../../../shared/form'
import remoteData, { RemoteData } from '../../../shared/remote-data'
import { CreateUserModel, UserModel } from '../../../api/user'
import { Notification } from '../../../components/notifications'

// STATE

export type State =
  | { type: UserCreationType.NotStarted }
  | {
    type: UserCreationType.InProgress
    form: CreateUserForm
    save: RemoteData<Error, {}>
  }

export type CreateUserForm = Form.Form<{
  email: string
  fullName: string
}>

export enum UserCreationType {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress'
}

export const initialState: State = {
  type: UserCreationType.NotStarted
}

// ACTIONS

export const CREATION_REQUESTED = 'CREATION_REQUESTED'
export const CREATION_CANCELED = 'CREATION_CANCELED'
export const CREATION_NAME_UPDATED = 'CREATION_NAME_UPDATED'
export const CREATION_EMAIL_UPDATED = 'CREATION_EMAIL_UPDATED'
export const CREATION_SAVE_REQUESTED = 'CREATION_SAVE_REQUESTED'
export const CREATION_SAVE_FAILED = 'CREATION_SAVE_FAILED'
export const CREATION_SAVE_SUCCEEDED = 'CREATION_SAVE_SUCCEEDED'

export type Action =
  | { type: typeof CREATION_REQUESTED }
  | { type: typeof CREATION_CANCELED }
  | { type: typeof CREATION_NAME_UPDATED, payload: string }
  | { type: typeof CREATION_EMAIL_UPDATED, payload: string }
  | { type: typeof CREATION_SAVE_REQUESTED }
  | { type: typeof CREATION_SAVE_FAILED, payload: Error }
  | { type: typeof CREATION_SAVE_SUCCEEDED, payload: { user: UserModel, notification: Notification } }

export const creationRequested = (): Action => ({
  type: CREATION_REQUESTED
})

export const creationCanceled = (): Action => ({
  type: CREATION_CANCELED
})

export const creationSaveRequested = (): Action => ({
  type: CREATION_SAVE_REQUESTED
})

export const creationSaveFailed = (error: Error): Action => ({
  type: CREATION_SAVE_FAILED,
  payload: error
})

export const creationSaveSucceeded = (user: UserModel, notification: Notification): Action => ({
  type: CREATION_SAVE_SUCCEEDED,
  payload: {
    user,
    notification
  }
})

export const creationNameUpdated = (value: string): Action => ({
  type: CREATION_NAME_UPDATED,
  payload: value
})

export const creationEmailUpdated = (value: string): Action => ({
  type: CREATION_EMAIL_UPDATED,
  payload: value
})

// REDUCER

export const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case CREATION_REQUESTED:
      return {
        ...state,
        type: UserCreationType.InProgress,
        form: {
          fullName: Form.initValid(''),
          email: Form.initValidated('', emailValidator)
        },
        save: remoteData.notAsked()
      }

    case CREATION_CANCELED:
      return {
        ...state,
        type: UserCreationType.NotStarted
      }

    case CREATION_SAVE_REQUESTED:
      return updateForm(
        updateSave(state, (_save, form) =>
          Form.isInvalid(form.fullName) || Form.isInvalid(form.email)
            ? remoteData.notAsked()
            : remoteData.loading()
        ),
        form => ({
          ...form,
          fullname: Form.toDirty(form.fullName),
          email: Form.toDirty(form.email)
        })
      )

    case CREATION_SAVE_FAILED:
      return updateSave(state, () => remoteData.failed(action.payload))

    case CREATION_SAVE_SUCCEEDED:
      return {
        type: UserCreationType.NotStarted
      }

    case CREATION_NAME_UPDATED:
      return updateForm(state, form => ({
        ...form,
        fullName: Form.toValid(form.fullName, action.payload)
      }))

    case CREATION_EMAIL_UPDATED:
      return updateForm(state, form => ({
        ...form,
        email: Form.toValidated(form.email, action.payload, emailValidator)
      }))

    default:
      return state
  }
}

const updateForm = (
  state: State,
  update: (form: CreateUserForm) => CreateUserForm
): State => {
  switch (state.type) {
    case UserCreationType.InProgress:
      return {
        ...state,
        form: update(state.form)
      }

    default:
      return state
  }
}

const updateSave = (
  state: State,
  update: (save: RemoteData<Error, {}>, form: CreateUserForm) => RemoteData<Error, {}>
): State => {
  switch (state.type) {
    case UserCreationType.InProgress:
      return {
        ...state,
        save: update(state.save, state.form)
      }

    default:
      return state
  }
}

// VALIDATION

const emailValidator: Form.Validator<string> = email => {
  if (/^\s*$/.test(email)) return USER_EMAIL_IS_REQUIRED
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) return USER_EMAIL_IS_INVALID
  return null
}

const USER_EMAIL_IS_REQUIRED = 'EMAIL_REQUIRED'
const USER_EMAIL_IS_INVALID = 'EMAIL_NOT_VALID'

// UTILITY

export const formToCreateUserModel = (form: CreateUserForm): CreateUserModel => ({
  email: form.email.value,
  fullName: /^\s*$/.test(form.fullName.value) ? null : form.fullName.value
})
