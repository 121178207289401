import React from 'react'
import { StoreContext, UserDeletionType, deletionConfirmed, deletionSucceeded, deletionFailed, deletionCanceled, UserDeletion } from './store'
import SessionContext from '../../contexts/session'
import TranslationsContext from '../../contexts/translations'
import { remove as removeUser } from '../../api/user'
import Translate, { translateString } from '../../components/translate'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import ErrorMessage from '../../components/error-message'
import { deletionSelfRequested } from './store/user-deletion'
import { useTheme } from '@mui/material/styles'

const DeleteConfirmation: React.FC = () => {
  const { state, dispatch } = React.useContext(StoreContext)
  const session = React.useContext(SessionContext)
  const translations = React.useContext(TranslationsContext)
  const theme = useTheme()

  if (state.userDeletion.type === UserDeletionType.NotStarted) return null

  const { user } = state.userDeletion

  const handleDelete = async () => {
    if (session.me.userId === user.id) {
      return dispatch(deletionSelfRequested(user))
    }

    dispatch(deletionConfirmed())

    try {
      await removeUser(session.token, user.id)
      dispatch(deletionSucceeded({
        id: Math.random(),
        message: translateString(translations, 'DELETE_USER_SUCCESS')
      }))
    } catch (error) {
      dispatch(deletionFailed(error))
    }
  }

  return (
    <Dialog
      data-testid='delete-confirmation'
      open={isOpen(state.userDeletion)}
      aria-labelledby='delete-confirmation-title'
      aria-describedby='delete-confirmation-description'
      onClose={() => dispatch(deletionCanceled())}
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogTitle id='delete-confirmation-title' sx={{ padding: theme.spacing(4, 4, 1) }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box component='div'>
            <Typography color='error' component='span' variant='h6'>
              <Translate>DELETE</Translate>:
            </Typography>
            &nbsp;
            <Typography component='span' variant='h6'>{user.fullName ?? user.email}</Typography>
          </Box>
          {state.userDeletion.type === UserDeletionType.Deleting && (
            <CircularProgress size={24} />
          )}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: theme.spacing(0, 4) }}>
        <DialogContentText
          id='delete-confirmation-description'
          sx={{ marginBottom: 0 }}
        >
          <Translate>DELETE_USER_CONFIRMATION</Translate>
        </DialogContentText>
        {state.userDeletion.type === UserDeletionType.Failed && (
          <Box mt={2}>
            <ErrorMessage>
              <Translate>DELETE_GENERIC_ERROR</Translate>
            </ErrorMessage>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: theme.spacing(4) }}>
        <Button
          disabled={state.userDeletion.type === UserDeletionType.Deleting}
          onClick={() => dispatch(deletionCanceled())}
        >
          <Translate>CANCEL</Translate>
        </Button>
        <Button
          data-testid='confirm'
          variant='outlined'
          disabled={state.userDeletion.type === UserDeletionType.Deleting}
          onClick={handleDelete}
        >
          <Translate>DELETE_USER</Translate>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteConfirmation

const isOpen = (userDeletion: UserDeletion): boolean => {
  switch (userDeletion.type) {
    case UserDeletionType.NotStarted:
    case UserDeletionType.Deleted:
    case UserDeletionType.SelfConfirming:
      return false

    default:
      return true
  }
}
