export type RemoteData<TError, TData> =
  | NotAsked
  | Loading
  | Failed<TError>
  | Succeeded<TData>

export type NotAsked = { type: RemoteDataType.NotAsked }
export type Loading = { type: RemoteDataType.Loading }
export type Failed<TError> = { type: RemoteDataType.Failed, error: TError }
export type Succeeded<TData> = { type: RemoteDataType.Succeeded, data: TData }

export enum RemoteDataType {
  NotAsked = 'NotAsked',
  Loading = 'Loading',
  Failed = 'Failed',
  Succeeded = 'Succeeded'
}

export const notAsked = <TError, TData>(): RemoteData<TError, TData> => ({
  type: RemoteDataType.NotAsked
})

export const loading = <TError, TData>(): RemoteData<TError, TData> => ({
  type: RemoteDataType.Loading
})

export const failed = <TError, TData>(error: TError): RemoteData<TError, TData> => ({
  type: RemoteDataType.Failed,
  error
})

export const succeeded = <TError, TData>(data: TData): RemoteData<TError, TData> => ({
  type: RemoteDataType.Succeeded,
  data
})

export const isLoading = <TError, TData>(
  remoteData: RemoteData<TError, TData>
): remoteData is Loading =>
    remoteData.type === RemoteDataType.Loading

export const isFailed = <TError, TData>(
  remoteData: RemoteData<TError, TData>
): remoteData is Failed<TError> =>
    remoteData.type === RemoteDataType.Failed

export const isSucceeded = <TError, TData>(
  remoteData: RemoteData<TError, TData>
): remoteData is Succeeded<TData> =>
    remoteData.type === RemoteDataType.Succeeded

export const map = <TError, TData, TResult>(
  remoteData: RemoteData<TError, TData>,
  mapper: (data: TData) => TResult
): RemoteData<TError, TResult> => {
  switch (remoteData.type) {
    case RemoteDataType.NotAsked:
    case RemoteDataType.Loading:
    case RemoteDataType.Failed:
      return remoteData

    case RemoteDataType.Succeeded:
      return succeeded(mapper(remoteData.data))
  }
}

export const match = <TError, TData, TResult>(
  remoteData: RemoteData<TError, TData>,
  matcher: {
    notAsked: () => TResult,
    loading: () => TResult,
    failed: (error: TError) => TResult,
    succeeded: (data: TData) => TResult
  }
): TResult => {
  switch (remoteData.type) {
    case RemoteDataType.NotAsked:
      return matcher.notAsked()

    case RemoteDataType.Loading:
      return matcher.loading()

    case RemoteDataType.Failed:
      return matcher.failed(remoteData.error)

    case RemoteDataType.Succeeded:
      return matcher.succeeded(remoteData.data)
  }
}

export default {
  notAsked,
  loading,
  failed,
  succeeded,
  isLoading,
  isFailed,
  isSucceeded,
  map,
  match
}
