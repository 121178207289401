import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const UsersIcon: React.FC = () => (<SvgIcon viewBox="0 0 24 24">
  <path fillRule="evenodd" clipRule="evenodd" d="M17.9318 11.7346C17.0424 12.6281 15.8118 13.182 14.4544 13.182C13.097 13.182 11.8664 12.6281 10.9771 11.7346C9.13611 12.8924 7.90881 14.9371 7.90881 17.273V18.0912H21V17.273C21 14.9371 19.7727 12.8924 17.9318 11.7346Z" fill="currentColor"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M14.4555 11.5456C16.2631 11.5456 17.7283 10.0803 17.7283 8.2728C17.7283 6.46528 16.2631 5 14.4555 5C12.648 5 11.1827 6.46528 11.1827 8.2728C11.1827 10.0803 12.648 11.5456 14.4555 11.5456Z" fill="currentColor"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M7.64083 12.7774C7.64083 12.7774 5.85246 12.6426 5.08235 11.8093C3.46838 13.4787 3 16.4548 3 16.4548C3 16.4548 5.12772 16.4548 6.28299 16.4548C6.6819 14.475 7.64083 12.7774 7.64083 12.7774Z" fill="currentColor"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M7.8266 11.2184C9.09187 11.2184 10.1176 10.1927 10.1176 8.92743C10.1176 7.66217 9.09187 6.63647 7.8266 6.63647C6.56134 6.63647 5.53564 7.66217 5.53564 8.92743C5.53564 10.1927 6.56134 11.2184 7.8266 11.2184Z" fill="currentColor"/>
</SvgIcon>)
export default UsersIcon
