import React from 'react'
import Translate, { translateString } from '../../components/translate'
import SessionContext, { Session } from '../../contexts/session'
import {
  StoreContext,
  RoleCreationType,
  creationCanceled,
  creationNameUpdated,
  creationDescriptionUpdated,
  creationSaveRequested,
  creationSaveSucceeded,
  creationSaveFailed,
  Action,
  State
} from './store'
import RoleForm from './role-form'
import { Form, isInvalid } from '../../shared/form'
import * as role from '../../api/role'
import remoteData, { RemoteData } from '../../shared/remote-data'
import TranslationsContext from '../../contexts/translations'
import { Translations } from '../../api/language'
import ActionDialog from '../../components/action-dialog'
import ApiResultErrorMessage from '../../components/api-result-error-message'

const AddRole: React.FC = () => {
  const { state, dispatch } = React.useContext(StoreContext)
  const session = React.useContext(SessionContext)
  const translations = React.useContext(TranslationsContext)

  return (
    <AddRoleHelper
      {...selectHelperProps(
        state,
        dispatch,
        session,
        translations
      )}
    />
  )
}

export default AddRole

type AddRoleHelperProps = {
  open: boolean
  saving: boolean
  form: null | Form<role.CreateRoleModel>
  saveResult: null | RemoteData<Error, {}>
  token: string
  translations: Translations
  dispatch: React.Dispatch<Action>
}

const AddRoleHelper: React.FC<AddRoleHelperProps> = (props) => {
  React.useEffect(() => {
    if (!props.saving || !props.form) return
    if (isInvalid(props.form.name) || isInvalid(props.form.description)) return

    let isMounted = true

    role.create(props.token, {
      name: props.form.name.value,
      description: props.form.description.value
    })
      .then(
        createdRole => creationSaveSucceeded(createdRole, {
          id: Math.random(),
          message: translateString(props.translations, 'ROLE_CREATED_SUCCESSFULLY')
        }),
        creationSaveFailed
      )
      .then(action => isMounted && props.dispatch(action))

    return () => { isMounted = false }
  }, [props.saving])

  return (
    <ActionDialog
      title='ADD_ROLE'
      open={props.open}
      saving={props.saving}
      readOnly={false}
      onSave={() => props.dispatch(creationSaveRequested())}
      onCancel={() => props.dispatch(creationCanceled())}
    >
      {props.form && (
        <RoleForm
          {...props.form}
          disabled={props.saving}
          translations={props.translations}
          onNameChange={(value) => props.dispatch(creationNameUpdated(value))}
          onDescriptionChange={(value) => props.dispatch(creationDescriptionUpdated(value))}
        />
      )}
      {props.saveResult && (
        <ApiResultErrorMessage
          result={props.saveResult}
          fallbackMessage={<Translate>CREATE_GENERIC_ERROR</Translate>}
        />
      )}
    </ActionDialog>
  )
}

const selectHelperProps = (
  state: State,
  dispatch: React.Dispatch<Action>,
  session: Session,
  translations: Translations
): AddRoleHelperProps => {
  switch (state.roleCreation.type) {
    case RoleCreationType.InProgress:
      return {
        translations,
        dispatch,
        token: session.token,
        open: true,
        saving: remoteData.isLoading(state.roleCreation.save),
        saveResult: state.roleCreation.save,
        form: state.roleCreation.form
      }

    default:
      return {
        translations,
        dispatch,
        token: session.token,
        open: false,
        saving: false,
        saveResult: null,
        form: null
      }
  }
}
