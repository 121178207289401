import React from 'react'
import { Me } from '../api/me'

export type Session = {
  token: string
  me: Me
}

export default React.createContext<Session>({
  token: '',
  me: {
    userId: '',
    displayName: '',
    permissions: {
      view: false,
      manage: false
    }
  }
})
