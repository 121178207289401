import React from 'react'
import Translate, { translateString } from '../../components/translate'
import {
  StoreContext,
  UserCreationType,
  creationCanceled,
  creationNameUpdated,
  creationEmailUpdated,
  creationSaveRequested,
  creationSaveSucceeded,
  creationSaveFailed,
  Action,
  State
} from './store'
import SessionContext, { Session } from '../../contexts/session'
import TranslationsContext from '../../contexts/translations'
import { isInvalid } from '../../shared/form'
import * as user from '../../api/user'
import remoteData, { RemoteData } from '../../shared/remote-data'
import { Translations } from '../../api/language'
import ActionDialog from '../../components/action-dialog'
import UserForm from './user-form'
import ApiResultErrorMessage from '../../components/api-result-error-message'
import { CreateUserForm, formToCreateUserModel } from './store/user-creation'

const AddUser: React.FC = () => {
  const { state, dispatch } = React.useContext(StoreContext)
  const session = React.useContext(SessionContext)
  const translations = React.useContext(TranslationsContext)

  return (
    <AddUserHelper
      {...selectHelperProps(
        state,
        dispatch,
        session,
        translations
      )}
    />
  )
}

export default AddUser

type AddUserHelperProps = {
  open: boolean,
  saving: boolean,
  form: null | CreateUserForm
  saveResult: null | RemoteData<Error, {}>
  token: string
  translations: Translations
  dispatch: React.Dispatch<Action>
}

const AddUserHelper: React.FC<AddUserHelperProps> = (props) => {
  React.useEffect(() => {
    if (!props.saving || !props.form) return
    if (isInvalid(props.form.email)) return

    let isMounted = true

    user.create(props.token, formToCreateUserModel(props.form))
      .then(
        createdUser => creationSaveSucceeded(createdUser, {
          id: Math.random(),
          message: translateString(props.translations, 'USER_CREATED_SUCCESSFULLY')
        }),
        creationSaveFailed
      )
      .then(action => isMounted && props.dispatch(action))

    return () => { isMounted = false }
  }, [props.saving])

  return (
    <ActionDialog
      title='ADD_USER'
      open={props.open}
      saving={props.saving}
      readOnly={false}
      onSave={() => props.dispatch(creationSaveRequested())}
      onCancel={() => props.dispatch(creationCanceled())}
    >
      {props.form && (
        <UserForm
          {...props.form}
          disabled={props.saving}
          translations={props.translations}
          onNameChange={(value) => props.dispatch(creationNameUpdated(value))}
          onEmailChange={(value) => props.dispatch(creationEmailUpdated(value))}
        />
      )}
      {props.saveResult && (
        <ApiResultErrorMessage
          result={props.saveResult}
          fallbackMessage={<Translate>CREATE_GENERIC_ERROR</Translate>}
        />
      )}
    </ActionDialog>
  )
}

const selectHelperProps = (
  state: State,
  dispatch: React.Dispatch<Action>,
  session: Session,
  translations: Translations
): AddUserHelperProps => {
  switch (state.userCreation.type) {
    case UserCreationType.InProgress:
      return {
        translations,
        dispatch,
        token: session.token,
        open: true,
        saving: remoteData.isLoading(state.userCreation.save),
        saveResult: state.userCreation.save,
        form: state.userCreation.form
      }

    default:
      return {
        translations,
        dispatch,
        token: session.token,
        open: false,
        saving: false,
        saveResult: null,
        form: null
      }
  }
}
