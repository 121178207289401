import React from 'react'
import { RoleModel, SetRoleUsersModel } from '../../../api/role'
import remoteData, { RemoteData } from '../../../shared/remote-data'
import { UserModel } from '../../../api/user'
import { Form, initValid, toValid } from '../../../shared/form'
import { Notification } from '../../../components/notifications'

// STATE

export type State =
  | { type: ManageUsersType.NotStarted }
  | {
      type: ManageUsersType.InProgress
      role: RoleModel
      allUsers: RemoteData<Error, UserModel[]>
      form: Form<SetRoleUsersModel>
      save: RemoteData<Error, {}>
    }

export enum ManageUsersType {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress'
}

export const initialState: State = {
  type: ManageUsersType.NotStarted
}

// ACTION

export const MANAGE_USERS_REQUESTED = 'MANAGE_USERS_REQUESTED'
export const MANAGE_USERS_CANCELED = 'MANAGE_USERS_CANCELED'
export const MANAGE_USERS_FETCH_USERS_FAILED = 'MANAGE_USERS_FETCH_USERS_FAILED'
export const MANAGE_USERS_FETCH_USERS_SUCCEEDED = 'MANAGE_USERS_FETCH_USERS_SUCCEEDED'
export const MANAGE_USERS_TOGGLE_USER = 'MANAGE_USERS_TOGGLE_USER '
export const MANAGE_USERS_SAVE_REQUESTED = 'MANAGE_USERS_SAVE_REQUESTED'
export const MANAGE_USERS_SAVE_FAILED = 'MANAGE_USERS_SAVE_FAILED'
export const MANAGE_USERS_SAVE_SUCCEEDED = 'MANAGE_USERS_SAVE_SUCCEEDED'

export type Action =
  | { type: typeof MANAGE_USERS_REQUESTED, payload: RoleModel }
  | { type: typeof MANAGE_USERS_CANCELED }
  | { type: typeof MANAGE_USERS_FETCH_USERS_FAILED, payload: Error }
  | { type: typeof MANAGE_USERS_FETCH_USERS_SUCCEEDED, payload: UserModel[] }
  | { type: typeof MANAGE_USERS_TOGGLE_USER, payload: { userId: string, selected: boolean } }
  | { type: typeof MANAGE_USERS_SAVE_REQUESTED }
  | { type: typeof MANAGE_USERS_SAVE_FAILED, payload: Error }
  | { type: typeof MANAGE_USERS_SAVE_SUCCEEDED, payload: Notification }

export const manageUsersRequested = (role: RoleModel): Action => ({
  type: MANAGE_USERS_REQUESTED,
  payload: role
})

export const manageUsersCanceled = (): Action => ({
  type: MANAGE_USERS_CANCELED
})

export const manageUsersFetchUsersFailed = (error: Error): Action => ({
  type: MANAGE_USERS_FETCH_USERS_FAILED,
  payload: error
})

export const manageUsersFetchUsersSucceeded = (users: UserModel[]): Action => ({
  type: MANAGE_USERS_FETCH_USERS_SUCCEEDED,
  payload: users
})

export const manageUsersToggleUser = (userId: string, selected: boolean): Action => ({
  type: MANAGE_USERS_TOGGLE_USER,
  payload: {
    userId,
    selected
  }
})

export const manageUsersSaveRequested = (): Action => ({
  type: MANAGE_USERS_SAVE_REQUESTED
})

export const manageUsersSaveFailed = (error: Error): Action => ({
  type: MANAGE_USERS_SAVE_FAILED,
  payload: error
})

export const manageUsersSaveSucceeded = (notification: Notification): Action => ({
  type: MANAGE_USERS_SAVE_SUCCEEDED,
  payload: notification
})

// REDUCER

export const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case MANAGE_USERS_REQUESTED:
      return {
        type: ManageUsersType.InProgress,
        role: action.payload,
        allUsers: remoteData.loading(),
        form: {
          userIds: initValid(action.payload.roleUsers.map(roleUser => roleUser.id))
        },
        save: remoteData.notAsked()
      }

    case MANAGE_USERS_SAVE_SUCCEEDED:
    case MANAGE_USERS_CANCELED:
      return {
        type: ManageUsersType.NotStarted
      }

    case MANAGE_USERS_FETCH_USERS_FAILED:
      if (state.type !== ManageUsersType.InProgress) return state
      return {
        ...state,
        allUsers: remoteData.failed(action.payload)
      }

    case MANAGE_USERS_FETCH_USERS_SUCCEEDED:
      if (state.type !== ManageUsersType.InProgress) return state
      return {
        ...state,
        allUsers: remoteData.succeeded(action.payload)
      }

    case MANAGE_USERS_TOGGLE_USER:
      return updateForm(state, form => ({
        ...form,
        userIds: action.payload.selected
          ? toValid(
            form.userIds,
            [...form.userIds.value, action.payload.userId]
          )
          : toValid(
            form.userIds,
            form.userIds.value.filter(userId => userId !== action.payload.userId)
          )
      }))

    case MANAGE_USERS_SAVE_REQUESTED:
      return updateSave(state, () => remoteData.loading())

    case MANAGE_USERS_SAVE_FAILED:
      return updateSave(state, () => remoteData.failed(action.payload))

    default:
      return state
  }
}

const updateForm = (
  state: State,
  update: (form: Form<SetRoleUsersModel>) => Form<SetRoleUsersModel>
): State => {
  switch (state.type) {
    case ManageUsersType.InProgress:
      return {
        ...state,
        form: update(state.form)
      }

    default:
      return state
  }
}

const updateSave = (
  state: State,
  update: (save: RemoteData<Error, {}>) => RemoteData<Error, {}>
): State => {
  switch (state.type) {
    case ManageUsersType.InProgress:
      return {
        ...state,
        save: update(state.save)
      }

    default:
      return state
  }
}
