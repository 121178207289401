import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import Routes from './routes'
import { theme } from '../../shared/theme'
import DependenciesLoader from './dependencies-loader'

const Root: React.FC = () => (
  <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DependenciesLoader>
        <BrowserRouter>
          <Routes></Routes>
        </BrowserRouter>
      </DependenciesLoader>
    </ThemeProvider>
  </>
)

export default Root
