import * as decode from 'decoders'
import { handleJsonResponse, handleResponse } from './common'

export type RoleModel = {
  name: string
  description: null | string
  roleUsers: RoleUserModel[]
  deletable: boolean
}

export type PermissionModel = {
  id: string
  name: string
  description: null | string
}
export type RolePermissionModel = {
  id: string
  roleName: string
  permissionId: string
}

export type CreateRoleModel = {
  name: string
  description: null | string
}

export type UpdateRoleModel = {
  description: null | string
}

export type RoleUserModel = {
  id: string
  fullName: null | string
  email: string
}

export type SetRoleUsersModel = {
  userIds: string[]
}

export type SetRolePermissionsModel = {
  permissionIds: string[]
}

export const getAll = async (token: string): Promise<RoleModel[]> => {
  const response = await fetch('/api/roles', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (!response.ok) throw Error(response.statusText)

  const json = await response.json()
  const roles = decode.guard(decode.array(roleDecoder))(json)

  return roles.sort((a, b) => a.name.localeCompare(b.name))
}

const roleUserDecoder: decode.Decoder<RoleUserModel> = decode.object({
  id: decode.string,
  fullName: decode.nullable(decode.string),
  email: decode.string
})

const roleDecoder: decode.Decoder<RoleModel> = decode.object({
  name: decode.string,
  description: decode.nullable(decode.string),
  roleUsers: decode.array(roleUserDecoder),
  deletable: decode.boolean
})

export const remove = async (token: string, roleName: string): Promise<void> => {
  const response = await fetch(`/api/roles/${roleName}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (!response.ok) throw Error(response.statusText)
}

export const create = async (token: string, role: CreateRoleModel): Promise<RoleModel> => {
  const response = await fetch('/api/roles', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(role)
  })

  return handleJsonResponse(response, decode.guard(roleDecoder))
}

export const update = async (token: string, roleName: string, role: UpdateRoleModel): Promise<void> => {
  const response = await fetch(`/api/roles/${roleName}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(role)
  })

  await handleResponse(response)
}

export const updateUsers = async (
  token: string,
  roleName: string,
  userRoles: SetRoleUsersModel
): Promise<void> => {
  const response = await fetch(`/api/roles/${roleName}/users`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(userRoles)
  })

  await handleResponse(response)
}

export const getAllPermissions = async (token: string): Promise<PermissionModel[]> => {
  const response = await fetch('/api/permissions', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (!response.ok) throw Error(response.statusText)

  const json = await response.json()
  const permissions = decode.guard(decode.array(permissionDecoder))(json)

  return permissions.sort((a, b) => a.name.localeCompare(b.name))
}

const permissionDecoder: decode.Decoder<PermissionModel> = decode.object({
  id: decode.string,
  name: decode.string,
  description: decode.nullable(decode.string)
})

export const getRolePermissions = async (token: string, roleName: string): Promise<RolePermissionModel[]> => {
  const response = await fetch(`/api/grants/role/${roleName}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (!response.ok) throw Error(response.statusText)

  const json = await response.json()
  return decode.guard(decode.array(rolePermissionDecoder))(json)
}

const rolePermissionDecoder: decode.Decoder<RolePermissionModel> = decode.object({
  id: decode.string,
  roleName: decode.string,
  permissionId: decode.string
})

export const updatePermissions = async (
  token: string,
  roleName: string,
  rolePermissions: SetRolePermissionsModel
): Promise<void> => {
  const response = await fetch(`/api/roles/${roleName}/grants`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(rolePermissions)
  })

  await handleResponse(response)
}
