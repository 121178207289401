import React from 'react'
import remoteData, { RemoteData } from '../shared/remote-data'
import Box from '@mui/material/Box'
import { ApiError, UnauthorizedError } from '../api/common'
import ApiErrorMessage from './api-error-message'
import ErrorMessage from './error-message'
import UserManagerContext from '../contexts/user-manager'

const ApiResultErrorMessage: React.FC<{
  result: RemoteData<Error, {}>,
  fallbackMessage: React.ReactNode
}> = ({ result, fallbackMessage }) => {
  if (!remoteData.isFailed(result)) return null

  const userManager = React.useContext(UserManagerContext)
  React.useEffect(() => {
    if (result.error instanceof UnauthorizedError) {
      userManager.instance.signoutRedirect()
    }
  }, [result.error, userManager])

  return (
    <Box mt={2}>
      {result.error instanceof ApiError
        ? (<ApiErrorMessage error={result.error} />)
        : (
          <ErrorMessage>
            {fallbackMessage}
          </ErrorMessage>
          )
      }
    </Box>
  )
}

export default ApiResultErrorMessage
