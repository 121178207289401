import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import ArrowDropdown from '@mui/icons-material/ArrowDropDown'
import Person from '@mui/icons-material/Person'
import Translate from '../translate'
import SessionContext from '../../contexts/session'
import UserManagerContext from '../../contexts/user-manager'
import { useTheme } from '@mui/material/styles'

const UserMenu: React.FC = () => {
  const userManager = React.useContext(UserManagerContext)
  const session = React.useContext(SessionContext)
  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null)
  const theme = useTheme()

  const handleOpen = (event: React.MouseEvent) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const handleLogout = () => userManager.instance.signoutRedirect()

  return (
    <>
      <Button
        data-testid='user-menu-button'
        aria-controls='user-menu'
        aria-haspopup='true'
        onClick={handleOpen}
        sx={{
          color: 'inherit',
          fontWeight: 'normal',
          textTransform: 'none'
        }}
      >
        {session.me.displayName}
        <ArrowDropdown />
      </Button>
      <Menu
        id='user-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          data-testid='user-menu-logout'
          onClick={handleLogout}
          sx={{
            color: theme.palette.grey[800],
            fontSize: theme.typography.body2.fontSize
          }}
        >
          <Person sx={{ marginRight: theme.spacing(1) }} />
          <Translate>LOGOUT</Translate>
        </MenuItem>
      </Menu>
    </>
  )
}

export default UserMenu
