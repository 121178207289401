import React from 'react'
import Button from '@mui/material/Button'
import PersonIcon from '@mui/icons-material/Person'
import SchoolIcon from '@mui/icons-material/School'
import * as url from '../../shared/url'
import RouteLink from '../route-link'
import { useHistory } from 'react-router-dom'
import Translate from '../translate'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/system/styleFunctionSx'
import { Theme } from '@mui/material/styles'

const InternalNavigation: React.FC = () => {
  return (
    <Box data-testid='internal-navigation' display='flex'>
      <NavigationItem to={url.users} icon={<PersonIcon />}>USERS</NavigationItem>
      <NavigationItem to={url.roles} icon={<SchoolIcon />}>ROLES</NavigationItem>
    </Box>
  )
}

export default InternalNavigation

const NavigationItem: React.FC<{
  to: string,
  icon: React.ReactElement,
  children: string
}> = (props) => {
  const history = useHistory()

  return (
    <Box ml={1}>
      <RouteLink to={props.to}>
        <Button
          sx={history.location.pathname === props.to ? activeButtonStyles : inactiveButtonStyles}
          variant='contained'
          startIcon={props.icon}
          disableElevation
        >
          <Translate>{props.children}</Translate>
        </Button>
      </RouteLink>
    </Box>
  )
}

const activeButtonStyles: SxProps<Theme> = {
  backgroundColor: '#fff',
  boxShadow: '0 2px 4px rgba(0,0,0,.15)',
  color: '#12576C',
  '&:hover': {
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgba(0,0,0,.25), 0 2px 4px rgba(0,0,0,.15)'
  }
}

const inactiveButtonStyles: SxProps<Theme> = {
  color: '#000',
  backgroundColor: 'rgba(0,0,0,.12)',
  opacity: 0.7,
  '&:hover': {
    backgroundColor: '#eee',
    opacity: 1
  }
}
