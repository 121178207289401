const PREFERRED_CULTURE = 'PREFERRED_CULTURE'

export const getPreferredCulture = (): null | string =>
  localStorage.getItem(PREFERRED_CULTURE)

export const setPreferredCulture = (culture: string): void =>
  localStorage.setItem(PREFERRED_CULTURE, culture)

export const removePreferredCulture = (): void =>
  localStorage.removeItem(PREFERRED_CULTURE)
