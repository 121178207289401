import React from 'react'
import Translate, { translateString } from '../../components/translate'
import Box from '@mui/material/Box'
import remoteData from '../../shared/remote-data'
import { getAll as getAllRoles, RoleModel } from '../../api/role'
import SessionContext from '../../contexts/session'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import InfoIcon from '@mui/icons-material/InfoRounded'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SettingsIcon from '@mui/icons-material/Settings'
import IconButton from '@mui/material/IconButton'
import DeleteConfirmation from './delete-confirmation'
import UserIcon from '../../components/custom-icons/users-icon'
import {
  deletionRequested,
  editingRequested,
  fetchRolesFailed,
  fetchRolesSucceeded,
  managePermissionsRequested,
  manageUsersRequested,
  StoreContext
} from './store'
import Tooltip from '@mui/material/Tooltip'
import TranslationsContext from '../../contexts/translations'
import LoadingRow from '../../components/loading-row'
import ErrorRow from '../../components/error-row'
import StyledTableCell from '../../components/table-cell'
import { SxProps } from '@mui/system/styleFunctionSx'
import { Theme, useTheme } from '@mui/material/styles'
import TableHeadCell from '../../components/table-head-cell'

const ListRoles: React.FC = () => {
  const { state, dispatch } = React.useContext(StoreContext)
  const session = React.useContext(SessionContext)

  React.useEffect(() => {
    let isMounted = true

    getAllRoles(session.token)
      .then(fetchRolesSucceeded, fetchRolesFailed)
      .then(action => isMounted && dispatch(action))

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell><Translate>NAME</Translate></TableHeadCell>
              <TableHeadCell><Translate>DESCRIPTION</Translate></TableHeadCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {remoteData.match(state.roles, {
              notAsked () {
                return <LoadingRow colSpan={3}/>
              },
              loading () {
                return <LoadingRow colSpan={3}/>
              },
              failed () {
                return <ErrorRow colSpan={3}/>
              },
              succeeded (roles) {
                return <RoleRows roles={roles} readOnly={!session.me.permissions.manage} />
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteConfirmation/>
    </>
  )
}

export default ListRoles

const RoleRows: React.FC<{
  roles: RoleModel[]
  readOnly: boolean
}> = ({ roles, readOnly }) => {
  const { dispatch } = React.useContext(StoreContext)
  const translations = React.useContext(TranslationsContext)
  const theme = useTheme()

  if (roles.length === 0) {
    return (
      <TableRow>
        <TableCell colSpan={3}>
          <Box display='flex' alignItems='center'>
            <Box component={InfoIcon} mr={1}/>
            <Translate>NO_ITEMS_AVAILABLE</Translate>
          </Box>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      {roles.map(role => (
        <TableRow
          key={role.name}
          data-testid={`role-${role.name}`}
          hover
          sx={{
            '&:hover [data-action]': {
              visibility: 'visible'
            }
          }}
        >
          <StyledTableCell sx={{ color: theme.palette.primary.main }}>{role.name}</StyledTableCell>
          <StyledTableCell>{role.description}</StyledTableCell>
          <StyledTableCell
            align='right'
            sx={{ padding: theme.spacing(0, 1, 0, 0) }}
          >
            {!readOnly && (
              <>
                <DeleteRoleAction
                  dataTestId={role.deletable ? 'delete' : 'undeletable_role'}
                  deletable={role.deletable}
                  onClick={() => dispatch(deletionRequested(role))}
                />

                <GenericRoleAction
                  dataTestId={'edit'}
                  title={translateString(translations, 'EDIT')}
                  icon={<EditIcon />}
                  onClick={() => dispatch(editingRequested(role))}
                />
              </>
            )}

            <GenericRoleAction
              dataTestId={'permissions'}
              title={translateString(translations, 'PERMISSIONS')}
              icon={<SettingsIcon />}
              onClick={() => dispatch(managePermissionsRequested(role))}
            />

            <GenericRoleAction
              dataTestId={'add_user'}
              title={translateString(translations, 'ADD_USER')}
              icon={<UserIcon />}
              onClick={() => dispatch(manageUsersRequested(role))}
            />
          </StyledTableCell>
        </TableRow>
      ))}
    </>
  )
}

const DeleteRoleAction: React.FC<{
  dataTestId?: string
  deletable: boolean,
  onClick: () => void
}> = React.memo(
  function DeleteRoleButton ({ deletable, onClick, dataTestId }) {
    const translations = React.useContext(TranslationsContext)
    const theme = useTheme()

    if (!deletable) {
      return (
        <Tooltip title={translateString(translations, 'UNDELETABLE_ROLE')}>
          <span>
            <IconButton
              data-action
              data-testid={dataTestId}
              disabled
              onClick={onClick}
              sx={deleteRoleActionStyles(theme)}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      )
    }

    return (
      <Tooltip title={translateString(translations, 'DELETE')}>
        <IconButton
          data-action
          data-testid={dataTestId}
          onClick={onClick}
          sx={deleteRoleActionStyles(theme)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    )
  }
)

const deleteRoleActionStyles = (theme: Theme): SxProps<Theme> => ({
  visibility: 'hidden',
  padding: theme.spacing(1),
  '&:hover': {
    color: '#EB5757'
  }
})

const GenericRoleAction: React.FC<{
  dataTestId?: string
  title: string
  icon: React.ReactElement
  onClick: () => void
}> = React.memo(
  function GenericRoleButton ({ title, icon, onClick, dataTestId }) {
    const theme = useTheme()

    return (
      <Tooltip title={title}>
        <IconButton
          data-action
          data-testid={dataTestId}
          onClick={onClick}
          sx={{
            visibility: 'hidden',
            padding: theme.spacing(1),
            '&:hover': {
              color: '#007899'
            }
          }}
        >
          {icon}
        </IconButton>
      </Tooltip>
    )
  }
)
