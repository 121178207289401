import * as React from 'react'

export const useDebounce = (
  callback: React.EffectCallback,
  dependencies: React.DependencyList,
  delay: number
): void => {
  React.useEffect(() => {
    let destructor: ReturnType<React.EffectCallback>

    const timeoutId = window.setTimeout(() => {
      destructor = callback()
    }, delay)

    return () => {
      clearTimeout(timeoutId)
      if (destructor) destructor()
    }
  }, dependencies)
}
