import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Box from '@mui/material/Box'

export type Notification = {
  id: number
  message: string
}

type NotificationsProps = {
  notifications: Notification[],
  onDismissNotification: (id: number) => void
}

const Notifications: React.FC<NotificationsProps> = ({ notifications, onDismissNotification }) => {
  return (
    <>
      {notifications.map(notification => (
        <Snackbar
          key={notification.id}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          open={true}
          autoHideDuration={4000}
          message={
            <Box display='flex' alignItems='center'>
              <CheckCircleIcon />
              <Box ml={1}>{notification.message}</Box>
            </Box>
          }
          onClose={() => onDismissNotification(notification.id)}
        />
      ))}
    </>
  )
}

export default Notifications
