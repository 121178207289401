import React from 'react'
import FormControl from '@mui/material/FormControl'
import Translate, { translateString } from '../../components/translate'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { Field, FieldType, isInvalid } from '../../shared/form'
import { Translations } from '../../api/language'

export type Props = {
  name: Field<string>
  description: Field<null | string>
  disabled: boolean
  translations: Translations
  onNameChange: (value: string) => void
  onDescriptionChange: (value: string) => void
}

const RoleForm: React.FC<Props> = (props) => {
  return (
    <>
      <FormControl fullWidth>
        <TextField
          variant='filled'
          label={<Translate>NAME</Translate>}
          id='role-name'
          required
          inputProps={{ maxLength: 255 }}
          disabled={props.disabled || props.name.readonly}
          error={!props.name.pristine && isInvalid(props.name)}
          value={props.name.value}
          onChange={(event) => props.onNameChange(event.target.value)}
          helperText={
            !props.name.pristine &&
            props.name.type === FieldType.Invalid &&
            translateString(props.translations, props.name.error)
          }
        />
      </FormControl>
      <Box mt={3}>
        <FormControl fullWidth>
          <TextField
            variant='filled'
            id='role-description'
            multiline
            label={<Translate>DESCRIPTION</Translate>}
            disabled={props.disabled || props.description.readonly}
            error={!props.name.pristine && isInvalid(props.description)}
            value={props.description.value || ''}
            onChange={(event) => props.onDescriptionChange(event.target.value)}
          />
        </FormControl>
      </Box>
    </>
  )
}

export default RoleForm
