import React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import SessionContext, { Session } from '../contexts/session'
import * as me from '../api/me'
import FatalError from './fatal-error'
import UserManagerContext, { UserManager } from '../contexts/user-manager'
import remoteData, { RemoteData, RemoteDataType } from '../shared/remote-data'

export type State = RemoteData<Error, Session>

const initialState: State = remoteData.loading()

const SessionLoader: React.FC = (props) => {
  const [state, setState] = React.useState<State>(initialState)
  const userManager = React.useContext(UserManagerContext)

  React.useEffect(() => init(setState, userManager), [userManager])

  switch (state.type) {
    case RemoteDataType.NotAsked:
    case RemoteDataType.Loading:
      return <LinearProgress />

    case RemoteDataType.Failed:
      return <FatalError>{state.error.message}</FatalError>

    case RemoteDataType.Succeeded:
      return (
        <SessionContext.Provider value={state.data}>
          {props.children}
        </SessionContext.Provider>
      )
  }
}

const init = (
  setState: (state: State) => void,
  userManager: UserManager
) => {
  let isMounted = true

  ;(async () => {
    try {
      const user = await userManager.instance.getUser()

      if (user === null) {
        return userManager.instance.signinRedirect()
      }

      const profile = await me.get(user.access_token)

      userManager.instance.events.addUserLoaded(u => {
        if (isMounted) {
          setState(remoteData.succeeded({
            token: u.access_token,
            me: profile
          }))
        }
      })

      if (user.expired) {
        // TODO: This should be properly handled in a future task
        return userManager.instance.signoutRedirect()
      }

      if (isMounted) {
        setState(remoteData.succeeded({
          token: user.access_token,
          me: profile
        }))
      }
    } catch (error) {
      if (isMounted) {
        setState(remoteData.failed(error))
      }
    }
  })()

  return () => { isMounted = false }
}

export default SessionLoader
