import React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import logo from '../../assets/img/logo.svg'
import Translate from './translate'
import { useTheme } from '@mui/material/styles'

type Props = {
  children?: {
    topLeft?: React.ReactElement
    topRight?: React.ReactElement
    middleRight?: React.ReactElement
  }
}

const Header: React.FC<Props> = (props) => {
  const theme = useTheme()

  return (
    <header>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          display: 'flex',
          justifyContent: 'space-between',
          minHeight: theme.spacing(4),
          padding: theme.spacing(0.5),
          alignItems: 'center'
        }}
      >
        <Box>{props.children && props.children.topLeft}</Box>
        <Box>{props.children && props.children.topRight}</Box>
      </Box>
      <Box bgcolor='secondary.main'>
        <Container
          maxWidth={false}
          sx={{
            paddingLeft: 0,
            paddingRight: 0
          }}
        >
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={{
              paddingLeft: theme.spacing(3),
              paddingRight: theme.spacing(3),
              [theme.breakpoints.up('sm')]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2)
              },
              [theme.breakpoints.up('lg')]: {
                paddingLeft: theme.spacing(10.625),
                paddingRight: theme.spacing(10.625)
              }
            }}
          >
            <h1
              css={{
                display: 'inline-flex',
                alignItems: 'center',
                fontSize: 24,
                color: theme.palette.primary.dark
              }}
            >
              <img src={logo} css={{ marginRight: theme.spacing(2) }} />
              <Translate>USERS_LOGO</Translate>
            </h1>
            <Box>{props.children && props.children.middleRight}</Box>
          </Box>
        </Container>
      </Box>
    </header>
  )
}

export default Header
