import * as decode from 'decoders'

export class UnauthorizedError extends Error {
  constructor () {
    super('Unauthorized')
  }
}

export class ApiError extends Error {
  public readonly details: ApiErrorDetails

  constructor (
    details: ApiErrorDetails
  ) {
    super('An API error occurred. Check "details" for more information.')
    this.details = details
  }
}

export type ApiErrorDetails = {
  [field: string]: string[]
}

const apiErrorDetailsDecoder: decode.Decoder<ApiErrorDetails> =
  decode.dict(decode.array(decode.string))

export const handleResponse = (response: Response): Promise<null> =>
  handleResponseHelper(response, async () => null)

export const handleJsonResponse = async <T>(
  response: Response,
  handler: (json: any) => T
): Promise<T> =>
  handleResponseHelper(
    response,
    async (res) => {
      const json = await res.json()
      return handler(json)
    }
  )

const handleResponseHelper = async <T>(
  response: Response,
  successHandler: (repsonse: Response) => Promise<T>
): Promise<T> => {
  if (response.ok) return await successHandler(response)

  let json: any

  try {
    json = await response.json()
  } catch {
    throw new Error(response.statusText)
  }

  if (response.status === 400) {
    const error = decode.guard(decode.object({ errors: apiErrorDetailsDecoder }))(json)
    throw new ApiError(error.errors)
  }

  if (response.status === 401) {
    throw new UnauthorizedError()
  }

  throw new ApiError(decode.guard(apiErrorDetailsDecoder)(json))
}
