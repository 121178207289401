import React from 'react'
import { useHistory } from 'react-router-dom'

export type Props = {
  to: string
}

const RouteLink: React.FC<Props> = (props) => {
  const history = useHistory()

  const navigate = (event: React.SyntheticEvent) => {
    event.preventDefault()
    history.push(props.to)
  }

  return (
    <a
      href={props.to}
      onClick={navigate}
      css={{
        display: 'inline-block',
        textDecoration: 'none'
      }}
    >{props.children}</a>
  )
}

export default RouteLink
