import * as decode from 'decoders'
import * as preferences from '../shared/preferences'

export type Culture = {
  name: string
  displayName: string
}

export type Translations = {
  culture: string
  resources: { [name: string]: string }
}

export const getTranslations = async (): Promise<Translations> => {
  const culture = preferences.getPreferredCulture()
  const headers = new Headers()

  if (culture !== null) headers.set('Accept-Language', culture)

  const response = await fetch('/api/language/translations', { headers })

  if (!response.ok) throw new Error(response.statusText)

  const json = await response.json()
  return decode.guard(translationsDecoder)(json)
}

export const getCultures = async (): Promise<Culture[]> => {
  const response = await fetch('/api/language/cultures')

  if (!response.ok) throw new Error(response.statusText)

  const json = await response.json()
  return decode.guard(decode.array(cultureDecoder))(json)
}

const translationsDecoder: decode.Decoder<Translations> =
  decode.object({
    culture: decode.string,
    resources: decode.dict(decode.string)
  })

const cultureDecoder: decode.Decoder<Culture> =
  decode.object({
    name: decode.string,
    displayName: decode.string
  })
