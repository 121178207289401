import React from 'react'
import { UserModel } from '../../../api/user'
import { Notification } from '../../../components/notifications'

// STATE

export enum UserDeletionType {
  NotStarted = 'NotStarted',
  Confirming = 'Confirming',
  SelfConfirming = 'SelfConfirming',
  Deleting = 'Deleting',
  Failed = 'Failed',
  Deleted = 'Deleted',
}

export type State =
  | { type: UserDeletionType.NotStarted }
  | { type: UserDeletionType.Confirming, user: UserModel }
  | { type: UserDeletionType.SelfConfirming, user: UserModel }
  | { type: UserDeletionType.Deleting, user: UserModel }
  | { type: UserDeletionType.Failed, user: UserModel, error: Error }
  | { type: UserDeletionType.Deleted, user: UserModel }

export const initialState: State = {
  type: UserDeletionType.NotStarted
}

// ACTIONS

export const DELETION_REQUESTED = 'USER/DELETION_REQUESTED'
export const DELETION_SELF_REQUESTED = 'USER/DELETION_SELF_REQUESTED'
export const DELETION_CONFIRMED = 'USER/DELETION_CONFIRMED'
export const DELETEION_SELF_CONFIRMED = 'USER/DELETION_SELF_CONFIRMED'
export const DELETION_CANCELED = 'USER/DELETION_CANCELED'
export const DELETION_SUCCEEDED = 'USER/DELETION_SUCCEEDED'
export const DELETION_FAILED = 'USER/DELETION_FAILED'

export type Action =
  | { type: typeof DELETION_REQUESTED, payload: { user: UserModel } }
  | { type: typeof DELETION_SELF_REQUESTED, payload: { user: UserModel } }
  | { type: typeof DELETION_CONFIRMED }
  | { type: typeof DELETEION_SELF_CONFIRMED }
  | { type: typeof DELETION_CANCELED }
  | { type: typeof DELETION_SUCCEEDED, payload: { notification: Notification } }
  | { type: typeof DELETION_FAILED, payload: { error: Error } }

export const deletionRequested = (user: UserModel): Action => ({
  type: DELETION_REQUESTED,
  payload: {
    user
  }
})

export const deletionSelfRequested = (user: UserModel): Action => ({
  type: DELETION_SELF_REQUESTED,
  payload: {
    user
  }
})

export const deletionConfirmed = (): Action => ({
  type: DELETION_CONFIRMED
})

export const deletionSelfConfirmed = (): Action => ({
  type: DELETEION_SELF_CONFIRMED
})

export const deletionCanceled = (): Action => ({
  type: DELETION_CANCELED
})

export const deletionSucceeded = (notification: Notification): Action => ({
  type: DELETION_SUCCEEDED,
  payload: {
    notification
  }
})

export const deletionFailed = (error: Error): Action => ({
  type: DELETION_FAILED,
  payload: {
    error
  }
})

// REDUCER

export const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case DELETION_REQUESTED:
      return {
        type: UserDeletionType.Confirming,
        user: action.payload.user
      }

    case DELETION_SELF_REQUESTED:
      return {
        type: UserDeletionType.SelfConfirming,
        user: action.payload.user
      }

    case DELETION_SUCCEEDED:
    case DELETION_CANCELED:
      return {
        type: UserDeletionType.NotStarted
      }

    case DELETION_CONFIRMED:
      switch (state.type) {
        case UserDeletionType.Confirming:
          return {
            type: UserDeletionType.Deleting,
            user: state.user
          }

        default:
          return state
      }
    case DELETEION_SELF_CONFIRMED:
      switch (state.type) {
        case UserDeletionType.SelfConfirming:
          return {
            type: UserDeletionType.Deleting,
            user: state.user
          }

        default:
          return state
      }

    case DELETION_FAILED:
      switch (state.type) {
        case UserDeletionType.Deleting:
          return {
            type: UserDeletionType.Failed,
            user: state.user,
            error: action.payload.error
          }

        default:
          return state
      }

    default:
      return state
  }
}

// CONTEXT

export type Store = {
  state: State
  dispatch: React.Dispatch<Action>
}

export const StoreContext = React.createContext<Store>({
  state: initialState,
  dispatch: () => {}
})
