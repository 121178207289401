import { createTheme } from '@mui/material/styles'

const primary = '#004457'
const primaryDark = '#002F40'
const secondary = '#00CF9D'
const errorMain = '#B00020'

export const theme = createTheme({
  palette: {
    primary: {
      main: primary,
      dark: primaryDark
    },
    secondary: {
      main: secondary
    },
    error: {
      main: errorMain
    }
  },
  typography: {
    fontFamily: [
      'Ubuntu',
      'sans-serif'
    ].join(', ')
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1024,
      xl: 1280
    }
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'rgba(0,0,0,.87)'
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,.04)',
          '&.Mui-focused': {
            backgroundColor: 'rgba(0, 68, 87, 0.08)'
          }
        }
      }
    }
  }
})
