import React from 'react'
import Typography from '@mui/material/Typography'

const ErrorMessage: React.FC = (props) => (
  <Typography
    component='div'
    color='error'
  >
    {props.children}
  </Typography>
)

export default ErrorMessage
