import React from 'react'
import {
  manageRolesFetchRolesFailed,
  manageRolesFetchRolesSucceeded,
  manageRolesToggleRole,
  State,
  StoreContext
} from './store'
import remoteData, { RemoteData, RemoteDataType } from '../../shared/remote-data'
import { Translations } from '../../api/language'
import SessionContext, { Session } from '../../contexts/session'
import {
  manageRolesCanceled,
  manageRolesSaveFailed,
  manageRolesSaveRequested,
  manageRolesSaveSucceeded,
  ManageRolesType
} from './store/manage-roles'
import { SetUserRolesModel, updateRoles, UserModel } from '../../api/user'
import TranslationsContext from '../../contexts/translations'
import { getAll as getAllRoles } from '../../api/role'
import { Form } from '../../shared/form'
import { translateString } from '../../components/translate'
import ManageRelations, { OptionModel } from '../../components/manage-relations'

const ManageRoles: React.FC = () => {
  const { state } = React.useContext(StoreContext)
  const session = React.useContext(SessionContext)
  const translations = React.useContext(TranslationsContext)

  return (
    <ManageRolesHelper
      {...selectHelperProps(
        state,
        session,
        translations
      )}
    />
  )
}

export default ManageRoles

type ManageRolesHelperProps = {
  open: boolean
  user: null | UserModel
  options: RemoteData<Error, OptionModel[]>
  form: null | Form<SetUserRolesModel>
  saving: boolean
  saveResult: RemoteData<Error, {}>
  token: string
  translations: Translations
  readOnly: boolean
}

const ManageRolesHelper: React.FC<ManageRolesHelperProps> = (props) => {
  const { dispatch } = React.useContext(StoreContext)

  React.useEffect(() => {
    if (props.options.type !== RemoteDataType.Loading) return
    let isMounted = true

    getAllRoles(props.token)
      .then(
        manageRolesFetchRolesSucceeded,
        manageRolesFetchRolesFailed
      )
      .then(action => isMounted && dispatch(action))

    return () => {
      isMounted = false
    }
  }, [props.options.type])

  React.useEffect(() => {
    if (!props.saving || !props.user || !props.form) return
    let isMounted = true

    updateRoles(props.token, props.user.id, {
      roleNames: props.form.roleNames.value
    })
      .then(
        () => manageRolesSaveSucceeded({
          id: Math.random(),
          message: translateString(
            props.translations,
            'USER_UPDATED_SUCCESSFULLY'
          )
        }),
        manageRolesSaveFailed
      )
      .then(action => isMounted && dispatch(action))

    return () => {
      isMounted = false
    }
  }, [props.saving])

  return (
    <ManageRelations
      open={props.open}
      title={props.user?.fullName ?? props.user?.email ?? ''}
      description={props.user && props.user.email ? props.user.email : ''}
      relationTitle='ROLES'
      onSave={() => dispatch(manageRolesSaveRequested())}
      onCancel={() => dispatch(manageRolesCanceled())}
      onChange={(id, checked) => dispatch(manageRolesToggleRole(id, checked))}
      options={props.options}
      saveResult={props.saveResult}
      readOnly={props.readOnly}
    />
  )
}

const selectHelperProps = (
  state: State,
  session: Session,
  translations: Translations
): ManageRolesHelperProps => {
  switch (state.manageRoles.type) {
    case ManageRolesType.InProgress: {
      const form = state.manageRoles.form
      return {
        translations,
        token: session.token,
        open: true,
        user: state.manageRoles.user,
        options: remoteData.map(state.manageRoles.allRoles,
          (roles) => roles.map(role => ({
            id: role.name,
            name: role.name,
            selected: form.roleNames.value.includes(role.name)
          }))
        ),
        form,
        saving: remoteData.isLoading(state.manageRoles.save),
        saveResult: state.manageRoles.save,
        readOnly: !session.me.permissions.manage
      }
    }

    default:
      return {
        translations,
        token: session.token,
        open: false,
        user: null,
        options: remoteData.notAsked(),
        form: null,
        saving: false,
        saveResult: remoteData.notAsked(),
        readOnly: !session.me.permissions.manage
      }
  }
}
