import React from 'react'
import { UserModel } from '../../../api/user'
import * as Form from '../../../shared/form'
import remoteData, { RemoteData } from '../../../shared/remote-data'
import { Notification } from '../../../components/notifications'

export type State =
  | ChangePasswordNotStarted
  | ChangePasswordStarted

export interface ChangePasswordNotStarted {
  readonly type: ChangePasswordType.NotStarted
}

export interface ChangePasswordStarted {
  readonly type: ChangePasswordType.Started
  readonly user: UserModel
  readonly form: ChangePasswordForm
  readonly save: RemoteData<Error, {}>
}

export type ChangePasswordForm = Form.Form<{
  readonly newPassword: string
  readonly newPasswordConfirmation: string
}>

export enum ChangePasswordType {
  NotStarted = 'NotStarted',
  Started = 'Started'
}

export const initialState: State = {
  type: ChangePasswordType.NotStarted
}

// ACTIONS

export const CHANGE_PASSWORD_REQUESTED = 'CHANGE_PASSWORD_REQUESTED'
export const CHANGE_PASSWORD_CANCELED = 'CHANGE_PASSWORD_CANCELED'
export const CHANGE_PASSWORD_NEW_PASSWORD_UPDATED = 'CHANGE_PASSWORD_NEW_PASSWORD_UPDATED'
export const CHANGE_PASSWORD_NEW_PASSWORD_CONFIRMATION_UPDATED = 'CHANGE_PASSWORD_NEW_PASSWORD_CONFIRMATION_UPDATED'
export const CHANGE_PASSWORD_SAVE_REQUESTED = 'CHANGE_PASSWORD_SAVE_REQUESTED'
export const CHANGE_PASSWORD_SAVE_FAILED = 'CHANGE_PASSWORD_SAVE_FAILED'
export const CHANGE_PASSWORD_SAVE_SUCCEEDED = 'CHANGE_PASSWORD_SAVE_SUCCEEDED'

export type Action =
  | { type: typeof CHANGE_PASSWORD_REQUESTED, payload: { user: UserModel } }
  | { type: typeof CHANGE_PASSWORD_CANCELED }
  | { type: typeof CHANGE_PASSWORD_NEW_PASSWORD_UPDATED, payload: string }
  | { type: typeof CHANGE_PASSWORD_NEW_PASSWORD_CONFIRMATION_UPDATED, payload: string }
  | { type: typeof CHANGE_PASSWORD_SAVE_REQUESTED }
  | { type: typeof CHANGE_PASSWORD_SAVE_FAILED, payload: { error: Error } }
  | { type: typeof CHANGE_PASSWORD_SAVE_SUCCEEDED, payload: { notification: Notification } }

export const changePasswordRequested = (user: UserModel): Action => ({
  type: CHANGE_PASSWORD_REQUESTED,
  payload: {
    user
  }
})

export const changePasswordCanceled = (): Action => ({
  type: CHANGE_PASSWORD_CANCELED
})

export const changePasswordNewPasswordUpdated = (value: string): Action => ({
  type: CHANGE_PASSWORD_NEW_PASSWORD_UPDATED,
  payload: value
})

export const changePasswordNewPasswordConfirmationUpdated = (value: string): Action => ({
  type: CHANGE_PASSWORD_NEW_PASSWORD_CONFIRMATION_UPDATED,
  payload: value
})

export const changePasswordSaveRequested = (): Action => ({
  type: CHANGE_PASSWORD_SAVE_REQUESTED
})

export const changePasswordSaveFailed = (error: Error): Action => ({
  type: CHANGE_PASSWORD_SAVE_FAILED,
  payload: {
    error
  }
})

export const changePasswordSaveSucceeded = (notification: Notification): Action => ({
  type: CHANGE_PASSWORD_SAVE_SUCCEEDED,
  payload: {
    notification
  }
})

// REDUCER

export const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUESTED:
      return {
        type: ChangePasswordType.Started,
        user: action.payload.user,
        form: {
          newPassword: Form.initInvalid('', NEW_PASSWORD_IS_REQUIRED),
          newPasswordConfirmation: Form.initInvalid('', NEW_PASSWORD_CONFIRMATION_IS_REQUIRED)
        },
        save: remoteData.notAsked()
      }

    case CHANGE_PASSWORD_SAVE_SUCCEEDED:
    case CHANGE_PASSWORD_CANCELED:
      return {
        type: ChangePasswordType.NotStarted
      }

    case CHANGE_PASSWORD_NEW_PASSWORD_UPDATED:
      return updateForm(
        state,
        form => ({
          ...form,
          newPassword: /^\s*$/.test(action.payload)
            ? Form.toInvalid(form.newPassword, action.payload, NEW_PASSWORD_IS_REQUIRED)
            : Form.toValid(form.newPassword, action.payload)
        })
      )

    case CHANGE_PASSWORD_NEW_PASSWORD_CONFIRMATION_UPDATED:
      return updateForm(
        state,
        form => ({
          ...form,
          newPasswordConfirmation: /^\s*$/.test(action.payload)
            ? Form.toInvalid(form.newPasswordConfirmation, action.payload, NEW_PASSWORD_CONFIRMATION_IS_REQUIRED)
            : form.newPassword.value === action.payload
              ? Form.toValid(form.newPasswordConfirmation, action.payload)
              : Form.toInvalid(form.newPasswordConfirmation, action.payload, NEW_PASSWORDS_DONT_MATCH)
        })
      )

    case CHANGE_PASSWORD_SAVE_REQUESTED:
      return {
        ...state,
        save: remoteData.loading()
      }

    case CHANGE_PASSWORD_SAVE_FAILED:
      return {
        ...state,
        save: remoteData.failed(action.payload.error)
      }

    default:
      return state
  }
}

const updateForm = (state: State, fn: (form: ChangePasswordForm) => ChangePasswordForm): State => {
  if (state.type !== ChangePasswordType.Started) return state
  return {
    ...state,
    form: fn(state.form)
  }
}

// UTIL

export const isValid = (form: ChangePasswordForm): boolean => {
  return Form.isValid(form.newPassword) && Form.isValid(form.newPasswordConfirmation)
}

// MESSAGES

const NEW_PASSWORD_IS_REQUIRED = 'NEW_PASSWORD_IS_REQUIRED'
const NEW_PASSWORD_CONFIRMATION_IS_REQUIRED = 'NEW_PASSWORD_CONFIRMATION_IS_REQUIRED'
const NEW_PASSWORDS_DONT_MATCH = 'NEW_PASSWORDS_DONT_MATCH'
