import React from 'react'
import { RoleModel } from '../../../api/role'
import remoteData, { RemoteData } from '../../../shared/remote-data'
import { SetUserRolesModel, UserModel } from '../../../api/user'
import { Form, initValid, toValid } from '../../../shared/form'
import { Notification } from '../../../components/notifications'

// STATE

export type State =
  | { type: ManageRolesType.NotStarted }
  | {
      type: ManageRolesType.InProgress
      user: UserModel
      allRoles: RemoteData<Error, RoleModel[]>
      form: Form<SetUserRolesModel>
      save: RemoteData<Error, {}>
    }

export enum ManageRolesType {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress'
}

export const initialState: State = {
  type: ManageRolesType.NotStarted
}

// ACTION

export const MANAGE_ROLES_REQUESTED = 'MANAGE_ROLES_REQUESTED'
export const MANAGE_ROLES_CANCELED = 'MANAGE_ROLES_CANCELED'
export const MANAGE_ROLES_FETCH_ROLES_FAILED = 'MANAGE_ROLES_FETCH_ROLES_FAILED'
export const MANAGE_ROLES_FETCH_ROLES_SUCCEEDED = 'MANAGE_ROLES_FETCH_ROLES_SUCCEEDED'
export const MANAGE_ROLES_TOGGLE_ROLE = 'MANAGE_ROLES_TOGGLE_ROLE '
export const MANAGE_ROLES_SAVE_REQUESTED = 'MANAGE_ROLES_SAVE_REQUESTED'
export const MANAGE_ROLES_SAVE_FAILED = 'MANAGE_ROLES_SAVE_FAILED'
export const MANAGE_ROLES_SAVE_SUCCEEDED = 'MANAGE_ROLES_SAVE_SUCCEEDED'

export type Action =
  | { type: typeof MANAGE_ROLES_REQUESTED, payload: UserModel }
  | { type: typeof MANAGE_ROLES_CANCELED }
  | { type: typeof MANAGE_ROLES_FETCH_ROLES_FAILED, payload: Error }
  | { type: typeof MANAGE_ROLES_FETCH_ROLES_SUCCEEDED, payload: RoleModel[] }
  | { type: typeof MANAGE_ROLES_TOGGLE_ROLE, payload: { roleName: string, selected: boolean } }
  | { type: typeof MANAGE_ROLES_SAVE_REQUESTED }
  | { type: typeof MANAGE_ROLES_SAVE_FAILED, payload: Error }
  | { type: typeof MANAGE_ROLES_SAVE_SUCCEEDED, payload: Notification }

export const manageRolesRequested = (user: UserModel): Action => ({
  type: MANAGE_ROLES_REQUESTED,
  payload: user
})

export const manageRolesCanceled = (): Action => ({
  type: MANAGE_ROLES_CANCELED
})

export const manageRolesFetchRolesFailed = (error: Error): Action => ({
  type: MANAGE_ROLES_FETCH_ROLES_FAILED,
  payload: error
})

export const manageRolesFetchRolesSucceeded = (roles: RoleModel[]): Action => ({
  type: MANAGE_ROLES_FETCH_ROLES_SUCCEEDED,
  payload: roles
})

export const manageRolesToggleRole = (roleName: string, selected: boolean): Action => ({
  type: MANAGE_ROLES_TOGGLE_ROLE,
  payload: {
    roleName,
    selected
  }
})

export const manageRolesSaveRequested = (): Action => ({
  type: MANAGE_ROLES_SAVE_REQUESTED
})

export const manageRolesSaveFailed = (error: Error): Action => ({
  type: MANAGE_ROLES_SAVE_FAILED,
  payload: error
})

export const manageRolesSaveSucceeded = (notification: Notification): Action => ({
  type: MANAGE_ROLES_SAVE_SUCCEEDED,
  payload: notification
})

// REDUCER

export const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case MANAGE_ROLES_REQUESTED:
      return {
        type: ManageRolesType.InProgress,
        user: action.payload,
        allRoles: remoteData.loading(),
        form: {
          roleNames: initValid(action.payload.userRoles.map(userRole => userRole.name))
        },
        save: remoteData.notAsked()
      }

    case MANAGE_ROLES_SAVE_SUCCEEDED:
    case MANAGE_ROLES_CANCELED:
      return {
        type: ManageRolesType.NotStarted
      }

    case MANAGE_ROLES_FETCH_ROLES_FAILED:
      if (state.type !== ManageRolesType.InProgress) return state
      return {
        ...state,
        allRoles: remoteData.failed(action.payload)
      }

    case MANAGE_ROLES_FETCH_ROLES_SUCCEEDED:
      if (state.type !== ManageRolesType.InProgress) return state
      return {
        ...state,
        allRoles: remoteData.succeeded(action.payload)
      }

    case MANAGE_ROLES_TOGGLE_ROLE:
      return updateForm(state, form => ({
        ...form,
        roleNames: action.payload.selected
          ? toValid(
            form.roleNames,
            [...form.roleNames.value, action.payload.roleName]
          )
          : toValid(
            form.roleNames,
            form.roleNames.value.filter(roleName => roleName !== action.payload.roleName)
          )
      }))

    case MANAGE_ROLES_SAVE_REQUESTED:
      return updateSave(state, () => remoteData.loading())

    case MANAGE_ROLES_SAVE_FAILED:
      return updateSave(state, () => remoteData.failed(action.payload))

    default:
      return state
  }
}

const updateForm = (
  state: State,
  update: (form: Form<SetUserRolesModel>) => Form<SetUserRolesModel>
): State => {
  switch (state.type) {
    case ManageRolesType.InProgress:
      return {
        ...state,
        form: update(state.form)
      }

    default:
      return state
  }
}

const updateSave = (
  state: State,
  update: (save: RemoteData<Error, {}>) => RemoteData<Error, {}>
): State => {
  switch (state.type) {
    case ManageRolesType.InProgress:
      return {
        ...state,
        save: update(state.save)
      }

    default:
      return state
  }
}
