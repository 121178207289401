import * as decode from 'decoders'

export type AuthSettings = {
  authority: string
  clientId: string
  scope: string
  enableLocalLogin: boolean
}

export type TopNavigationBar = {
  name: string,
  url: string
}

const authSettingsDecoder: decode.Decoder<AuthSettings> = decode.object({
  authority: decode.string,
  clientId: decode.string,
  scope: decode.string,
  enableLocalLogin: decode.boolean
})

export const getAuthSettings = async (): Promise<AuthSettings> => {
  const response = await fetch('/api/settings/auth')
  if (!response.ok) throw new Error(response.statusText)
  const json = await response.json()
  return decode.guard(authSettingsDecoder)(json)
}

const topNavigationBarDecoder: decode.Decoder<TopNavigationBar> =
  decode.object({
    name: decode.string,
    url: decode.string
  })

export const getTopNavigationBar = async (): Promise<TopNavigationBar[]> => {
  const response = await fetch('/api/settings/top-navigation-bar')

  if (!response.ok) throw new Error(response.statusText)

  const json = await response.json()
  return decode.guard(decode.array(topNavigationBarDecoder))(json)
}
