import React from 'react'
import Dialog from '@mui/material/Dialog'
import Translate from './translate'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

export type Props = {
  title: string
  subtitle?: string
  open: boolean
  saving: boolean
  readOnly: boolean
  onSave: () => void
  onCancel: () => void
}

const ActionDialog: React.FC<Props> = (props) => {
  const theme = useTheme()

  const save = (event: React.SyntheticEvent) => {
    event.preventDefault()
    props.onSave()
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onCancel}
    >
      <DialogTitle
        sx={{
          paddingLeft: theme.spacing(4),
          paddingTop: theme.spacing(4)
        }}
      >
        <Box>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Box>
              <Translate>{props.title}</Translate>

            </Box>
            {props.saving
              ? (
              <CircularProgress size={24}/>
                )
              : props.onCancel && (
              <IconButton
                aria-label="close"
                onClick={props.onCancel}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0
                }}
              >
                <CloseIcon/>
              </IconButton>
              )}
          </Box>
        </Box>
        {props.subtitle && (
          <Box mb={2}>
            <Typography
              component='div'
              color='textSecondary'
              variant='body2'
            >
              {props.subtitle}
            </Typography>
          </Box>
        )}

      </DialogTitle>
      <DialogContent sx={{ padding: theme.spacing(0, 4, 4) }}>
        <Box
          component='form'
          id='action-dialog-form'
          minWidth='min(40vw, 500px)'
          marginBottom={props.readOnly ? 3 : 0}
          onSubmit={save}
        >
          {props.children}
        </Box>
      </DialogContent>
      {!props.readOnly && (
        <DialogActions sx={{ padding: theme.spacing(0, 2, 2) }}>
          <Box mb={2}>
            <Button
              variant='text'
              disabled={props.saving}
              onClick={props.onCancel}
            >
              <Translate>CANCEL</Translate>
            </Button>
          </Box>
          <Box mr={2} mb={2}>
            <Button
              type='submit'
              form='action-dialog-form'
              variant='outlined'
              disabled={props.saving}
            >
              <Translate>UPDATE</Translate>
            </Button>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default ActionDialog
