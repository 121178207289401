import React from 'react'
import ReactDOM from 'react-dom'
import Root from './components/root'

import './index.css'

ReactDOM.render(
  <Root />,
  document.getElementById('main')
)
