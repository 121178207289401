import React from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import ErrorMessage from './error-message'
import Translate from './translate'

type Props = {
  colSpan: number
}

const ErrorRow: React.FC<Props> = ({ colSpan }) => (
  <TableRow>
    <TableCell colSpan={colSpan}>
      <ErrorMessage>
        <Translate>FETCH_GENERIC_ERROR</Translate>
      </ErrorMessage>
    </TableCell>
  </TableRow>
)

export default ErrorRow
