import React from 'react'
import UserManagerContext from '../contexts/user-manager'

const SilentRenew: React.FC = () => {
  const userManager = React.useContext(UserManagerContext)
  React.useEffect(() => { userManager.instance.signinSilentCallback() }, [userManager])
  return <div data-testid='silent-renew'></div>
}

export default SilentRenew
