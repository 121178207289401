import React from 'react'
import * as url from '../shared/url'
import UserManagerContext, { UserManager } from '../contexts/user-manager'
import { useHistory } from 'react-router-dom'

const Callback: React.FC = () => {
  const userManager = React.useContext(UserManagerContext)
  const history = useHistory()
  React.useEffect(() => { init(userManager, history) }, [userManager])
  return <div data-testid='callback'></div>
}

const init = async (
  userManager: UserManager,
  history: ReturnType<typeof useHistory>
): Promise<void> => {
  try {
    await userManager.instance.signinRedirectCallback()
  } catch (error) {
    console.warn(error)
  } finally {
    history.replace(url.users)
  }
}

export default Callback
