import React from 'react'
import remoteData, { RemoteData } from '../../../shared/remote-data'
import { RoleModel } from '../../../api/role'
import * as roleCreation from './role-creation'
import { Notification } from '../../../components/notifications'
import * as roleEditing from './role-editing'
import * as manageUsers from './manage-users'
import * as managePermissions from './manage-permissions'
export {
  State as RoleCreation,
  RoleCreationType,
  creationRequested,
  creationNameUpdated,
  creationDescriptionUpdated,
  creationCanceled,
  creationSaveRequested,
  creationSaveFailed,
  creationSaveSucceeded
} from './role-creation'
export {
  State as RoleEditing,
  RoleEditingType,
  editingRequested,
  editingCanceled,
  editingDescriptionUpdate,
  editingSaveFailed,
  editingSaveRequested,
  editingSaveSucceeded
} from './role-editing'
export {
  State as RoleUsers,
  ManageUsersType as RoleUsersType,
  manageUsersRequested,
  manageUsersCanceled,
  manageUsersFetchUsersFailed,
  manageUsersFetchUsersSucceeded,
  manageUsersToggleUser
} from './manage-users'
export {
  State as RolePermissions,
  ManagePermissionsType as RolePermissionsType,
  managePermissionsRequested,
  managePermissionsCanceled,
  managePermissionsTogglePermission,
  managePermissionsFetchPermissionsFailed,
  managePermissionsFetchPermissionsSucceeded
} from './manage-permissions'

// STATE

export type State = {
  roles: RemoteData<Error, RoleModel[]>
  roleDeletion: RoleDeletion
  roleCreation: roleCreation.State
  roleEditing: roleEditing.State
  manageUsers: manageUsers.State
  managePermissions: managePermissions.State
  notifications: Notification[]
}

export type RoleDeletion =
  | { type: RoleDeletionType.NotStarted }
  | { type: RoleDeletionType.Confirming, role: RoleModel }
  | { type: RoleDeletionType.Deleting, role: RoleModel }
  | { type: RoleDeletionType.Failed, role: RoleModel, error: Error }
  | { type: RoleDeletionType.Deleted, role: RoleModel }

export enum RoleDeletionType {
  NotStarted = 'NotStarted',
  Confirming = 'Confirming',
  Deleting = 'Deleting',
  Failed = 'Failed',
  Deleted = 'Deleted',
}

export const initialState: State = {
  roles: remoteData.loading(),
  roleDeletion: { type: RoleDeletionType.NotStarted },
  roleCreation: roleCreation.initialState,
  roleEditing: roleEditing.initialState,
  manageUsers: manageUsers.initialState,
  managePermissions: managePermissions.initialState,
  notifications: []
}

// ACTIONS

const FETCH_ROLES_SUCCEEDED = 'FETCH_ROLES_SUCCEEDED'
const FETCH_ROLES_FAILED = 'FETCH_ROLES_FAILED'
const DELETION_REQUESTED = 'DELETION_REQUESTED'
const DELETION_CONFIRMED = 'DELETION_CONFIRMED'
const DELETION_CANCELED = 'DELETION_CANCELED'
const DELETION_SUCCEEDED = 'DELETION_SUCCEEDED'
const DELETION_FAILED = 'DELETION_FAILED'
const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION'

export type Action =
  | { type: typeof FETCH_ROLES_SUCCEEDED, payload: { roles: RoleModel[] } }
  | { type: typeof FETCH_ROLES_FAILED, payload: { error: Error } }
  | { type: typeof DELETION_REQUESTED, payload: { role: RoleModel } }
  | { type: typeof DELETION_CONFIRMED }
  | { type: typeof DELETION_CANCELED }
  | { type: typeof DELETION_SUCCEEDED, payload: { notification: Notification } }
  | { type: typeof DELETION_FAILED, payload: { error: Error } }
  | { type: typeof DISMISS_NOTIFICATION, payload: { notificationId: number } }
  | roleCreation.Action
  | roleEditing.Action
  | manageUsers.Action
  | managePermissions.Action

export const fetchRolesSucceeded = (roles: RoleModel[]): Action => ({
  type: FETCH_ROLES_SUCCEEDED,
  payload: {
    roles
  }
})

export const fetchRolesFailed = (error: Error): Action => ({
  type: FETCH_ROLES_FAILED,
  payload: {
    error
  }
})

export const deletionRequested = (role: RoleModel): Action => ({
  type: DELETION_REQUESTED,
  payload: {
    role
  }
})

export const deletionConfirmed = (): Action => ({
  type: DELETION_CONFIRMED
})

export const deletionCanceled = (): Action => ({
  type: DELETION_CANCELED
})

export const deletionSucceeded = (notification: Notification): Action => ({
  type: DELETION_SUCCEEDED,
  payload: {
    notification
  }
})

export const deletionFailed = (error: Error): Action => ({
  type: DELETION_FAILED,
  payload: {
    error
  }
})

export const dismissNotification = (notificationId: number): Action => ({
  type: DISMISS_NOTIFICATION,
  payload: {
    notificationId
  }
})

// REDUCER

export const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case FETCH_ROLES_SUCCEEDED:
      return { ...state, roles: remoteData.succeeded(action.payload.roles) }

    case FETCH_ROLES_FAILED:
      return { ...state, roles: remoteData.failed(action.payload.error) }

    case DELETION_REQUESTED:
      return {
        ...state,
        roleDeletion: { type: RoleDeletionType.Confirming, role: action.payload.role }
      }

    case DELETION_CANCELED:
      return { ...state, roleDeletion: { type: RoleDeletionType.NotStarted } }

    case DELETION_CONFIRMED:
      switch (state.roleDeletion.type) {
        case RoleDeletionType.Confirming:
          return {
            ...state,
            roleDeletion: { type: RoleDeletionType.Deleting, role: state.roleDeletion.role }
          }

        default:
          return state
      }

    case DELETION_SUCCEEDED: {
      const { roleDeletion } = state

      if (roleDeletion.type !== RoleDeletionType.Deleting) return state

      return {
        ...state,
        roles: remoteData.map(
          state.roles,
          roles => roles.filter(role => role.name !== roleDeletion.role.name)
        ),
        roleDeletion: { type: RoleDeletionType.NotStarted },
        notifications: [
          ...state.notifications,
          action.payload.notification
        ]
      }
    }

    case DELETION_FAILED:
      switch (state.roleDeletion.type) {
        case RoleDeletionType.Deleting:
          return {
            ...state,
            roleDeletion: {
              type: RoleDeletionType.Failed,
              role: state.roleDeletion.role,
              error: action.payload.error
            }
          }

        default:
          return state
      }

    case DISMISS_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications
          .filter(notification => notification.id !== action.payload.notificationId)
      }

    case roleCreation.CREATION_SAVE_SUCCEEDED:
      return {
        ...state,
        roleCreation: roleCreation.reducer(state.roleCreation, action),
        roles: remoteData.map(state.roles, roles => [
          action.payload.role,
          ...roles
        ]),
        notifications: [
          ...state.notifications,
          action.payload.notification
        ]
      }

    case roleEditing.EDITING_SAVE_SUCCEEDED: {
      if (state.roleEditing.type !== roleEditing.RoleEditingType.InProgress) return state

      const updatedRole = {
        ...state.roleEditing.role,
        description: state.roleEditing.form.description.value
      }

      return {
        ...state,
        roleEditing: roleEditing.reducer(state.roleEditing, action),
        roles: remoteData.map(
          state.roles,
          roles => roles.map(
            role =>
              role.name === updatedRole.name
                ? updatedRole
                : role
          )
        ),
        notifications: [
          ...state.notifications,
          action.payload
        ]
      }
    }

    case manageUsers.MANAGE_USERS_SAVE_SUCCEEDED: {
      if (state.manageUsers.type !== manageUsers.ManageUsersType.InProgress) return state
      const { role, form, allUsers } = state.manageUsers
      if (!remoteData.isSucceeded(allUsers)) return state

      const updatedRole: RoleModel = {
        ...role,
        roleUsers: allUsers.data.filter(user => form.userIds.value.includes(user.id))
      }

      return {
        ...state,
        manageUsers: manageUsers.reducer(state.manageUsers, action),
        roles: remoteData.map(
          state.roles,
          roles => roles.map(r => r.name === updatedRole.name ? updatedRole : r)
        ),
        notifications: [
          ...state.notifications,
          action.payload
        ]
      }
    }

    case managePermissions.MANAGE_PERMISSIONS_SAVE_SUCCEEDED: {
      return {
        ...state,
        managePermissions: managePermissions.reducer(state.managePermissions, action),
        notifications: [
          ...state.notifications,
          action.payload
        ]
      }
    }

    default:
      return {
        ...state,
        roleCreation: roleCreation.reducer(
          state.roleCreation,
          action as roleCreation.Action
        ),
        roleEditing: roleEditing.reducer(
          state.roleEditing,
          action as roleEditing.Action
        ),
        manageUsers: manageUsers.reducer(
          state.manageUsers,
          action as manageUsers.Action
        ),
        managePermissions: managePermissions.reducer(
          state.managePermissions,
          action as managePermissions.Action
        )
      }
  }
}

// CONTEXT

export type Store = {
  state: State
  dispatch: React.Dispatch<Action>
}

export const StoreContext = React.createContext<Store>({
  state: initialState,
  dispatch: () => {}
})
