import * as decode from 'decoders'

export type Me = {
  userId: string
  displayName: string
  permissions: Permissions
}

export type Permissions = {
  view: boolean
  manage: boolean
}

export const get = async (token: string): Promise<Me> => {
  const response = await fetch('/api/me', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (!response.ok) throw new Error(response.statusText)

  const json = await response.json()
  return decode.guard(meDecoder)(json)
}

const meDecoder: decode.Decoder<Me> = decode.object({
  userId: decode.string,
  displayName: decode.string,
  permissions: decode.object({
    view: decode.boolean,
    manage: decode.boolean
  })
})
