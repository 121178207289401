import React from 'react'
import remoteData, { RemoteData, RemoteDataType } from '../shared/remote-data'
import { getTranslations, Translations } from '../api/language'
import TranslationsContext from '../contexts/translations'
import TopNavigationBarContext from '../contexts/top-navigation-bar'
import { getAuthSettings, getTopNavigationBar, TopNavigationBar } from '../api/settings'
import UserManagerContext, { UserManager, createUserManager } from '../contexts/user-manager'
import LinearProgress from '@mui/material/LinearProgress'
import FatalError from './fatal-error'

type State = RemoteData<Error, {
  translations: Translations
  userManager: UserManager
  topNavigationBar: TopNavigationBar[]
}>

const initialState: State = remoteData.loading()

const DependenciesLoader: React.FC = (props) => {
  const [state, setState] = React.useState<State>(initialState)

  React.useEffect(() => {
    let isMounted = true

    Promise.all([
      getAuthSettings(),
      getTranslations(),
      getTopNavigationBar()
    ])
      .then<State>(async ([authSettings, translations, topNavigationBar]) => {
        const userManager = createUserManager(authSettings)

        return remoteData.succeeded({
          translations,
          userManager,
          topNavigationBar
        })
      })
      .catch<State>(remoteData.failed)
      .then(newState => isMounted && setState(newState))

    return () => { isMounted = false }
  }, [])

  switch (state.type) {
    case RemoteDataType.NotAsked:
    case RemoteDataType.Loading:
      return <LinearProgress />

    case RemoteDataType.Failed:
      return <FatalError>{state.error.message}</FatalError>

    case RemoteDataType.Succeeded:
      return (
        <TopNavigationBarContext.Provider value={state.data.topNavigationBar}>
          <UserManagerContext.Provider value={state.data.userManager}>
            <TranslationsContext.Provider value={state.data.translations}>
              {props.children}
            </TranslationsContext.Provider>
          </UserManagerContext.Provider>
        </TopNavigationBarContext.Provider>
      )
  }
}

export default DependenciesLoader
