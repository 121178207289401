import React from 'react'
import {
  manageUsersFetchUsersFailed,
  manageUsersFetchUsersSucceeded,
  manageUsersToggleUser,
  State,
  StoreContext
} from './store'
import remoteData, { RemoteData, RemoteDataType } from '../../shared/remote-data'
import { Translations } from '../../api/language'
import SessionContext, { Session } from '../../contexts/session'
import {
  manageUsersCanceled,
  manageUsersSaveFailed,
  manageUsersSaveRequested,
  manageUsersSaveSucceeded,
  ManageUsersType
} from './store/manage-users'
import { RoleModel, SetRoleUsersModel, updateUsers } from '../../api/role'
import TranslationsContext from '../../contexts/translations'
import { getAll as getAllUsers } from '../../api/user'
import { Form } from '../../shared/form'
import { translateString } from '../../components/translate'
import ManageRelations, { OptionModel } from '../../components/manage-relations'

const ManageUsers: React.FC = () => {
  const { state } = React.useContext(StoreContext)
  const session = React.useContext(SessionContext)
  const translations = React.useContext(TranslationsContext)

  return (
    <ManageUsersHelper
      {...selectHelperProps(
        state,
        session,
        translations
      )}
    />
  )
}

export default ManageUsers

type ManageUsersHelperProps = {
  open: boolean
  role: null | RoleModel
  options: RemoteData<Error, OptionModel[]>
  form: null | Form<SetRoleUsersModel>
  saving: boolean
  saveResult: RemoteData<Error, {}>
  token: string
  translations: Translations
  readOnly: boolean
}

const ManageUsersHelper: React.FC<ManageUsersHelperProps> = (props) => {
  const { dispatch } = React.useContext(StoreContext)

  React.useEffect(() => {
    if (props.options.type !== RemoteDataType.Loading) return
    let isMounted = true

    getAllUsers(props.token)
      .then(
        manageUsersFetchUsersSucceeded,
        manageUsersFetchUsersFailed
      )
      .then(action => isMounted && dispatch(action))

    return () => { isMounted = false }
  }, [props.options.type])

  React.useEffect(() => {
    if (!props.saving || !props.role || !props.form) return
    let isMounted = true

    updateUsers(props.token, props.role.name, {
      userIds: props.form.userIds.value
    })
      .then(
        () => manageUsersSaveSucceeded({
          id: Math.random(),
          message: translateString(
            props.translations,
            'ROLE_UPDATED_SUCCESSFULLY'
          )
        }),
        manageUsersSaveFailed
      )
      .then(action => isMounted && dispatch(action))

    return () => { isMounted = false }
  }, [props.saving])

  return (
    <ManageRelations
      open={props.open}
      title={props.role ? props.role.name : ''}
      description={props.role && props.role.description ? props.role.description : ''}
      relationTitle='USERS'
      readOnly={props.readOnly}
      onSave={() => dispatch(manageUsersSaveRequested())}
      onCancel={() => dispatch(manageUsersCanceled())}
      onChange={(id, checked) => dispatch(manageUsersToggleUser(id, checked))}
      options={props.options}
      saveResult={props.saveResult}
    />
  )
}

const selectHelperProps = (
  state: State,
  session: Session,
  translations: Translations
): ManageUsersHelperProps => {
  switch (state.manageUsers.type) {
    case ManageUsersType.InProgress: {
      const form = state.manageUsers.form
      return {
        translations,
        token: session.token,
        open: true,
        role: state.manageUsers.role,
        options: remoteData.map(state.manageUsers.allUsers,
          (users) => users.map(user => ({
            id: user.id,
            name: user.email,
            selected: form.userIds.value.includes(user.id)
          }))
        ),
        form,
        saving: remoteData.isLoading(state.manageUsers.save),
        saveResult: state.manageUsers.save,
        readOnly: !session.me.permissions.manage
      }
    }

    default:
      return {
        translations,
        token: session.token,
        open: false,
        role: null,
        options: remoteData.notAsked(),
        form: null,
        saving: false,
        saveResult: remoteData.notAsked(),
        readOnly: !session.me.permissions.manage
      }
  }
}
