import React from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import LinearProgress from '@mui/material/LinearProgress'

type Props = {
  colSpan: number
}

const LoadingRow: React.FC<Props> = ({ colSpan }) => (
  <TableRow>
    <TableCell colSpan={colSpan}>
      <LinearProgress />
    </TableCell>
  </TableRow>
)

export default LoadingRow
