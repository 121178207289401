import React from 'react'
import { StoreContext, Action, editingCanceled, RoleEditingType, editingDescriptionUpdate, editingSaveRequested, editingSaveSucceeded, editingSaveFailed, State } from './store'
import ActionDialog from '../../components/action-dialog'
import RoleForm from './role-form'
import { Form, initReadonly } from '../../shared/form'
import { UpdateRoleModel } from '../../api/role'
import SessionContext, { Session } from '../../contexts/session'
import * as role from '../../api/role'
import { translateString } from '../../components/translate'
import { Translations } from '../../api/language'
import TranslationsContext from '../../contexts/translations'
import remoteData, { RemoteData } from '../../shared/remote-data'
import ApiErrorMessage from '../../components/api-error-message'
import ErrorMessage from '../../components/error-message'
import Box from '@mui/material/Box'
import { ApiError } from '../../api/common'

const EditRole: React.FC = () => {
  const { state, dispatch } = React.useContext(StoreContext)
  const session = React.useContext(SessionContext)
  const translations = React.useContext(TranslationsContext)

  return (
    <EditRoleHelper
      {...selectHelperProps(
        state,
        dispatch,
        session,
        translations
      )}
    />
  )
}

export default EditRole

type EditRoleHelperProps = {
  open: boolean
  saving: boolean
  role: null | role.RoleModel
  form: null | Form<UpdateRoleModel>
  saveResult: null | RemoteData<Error, {}>
  token: string
  translations: Translations
  dispatch: React.Dispatch<Action>
}

const EditRoleHelper: React.FC<EditRoleHelperProps> = (props) => {
  React.useEffect(() => {
    if (!props.saving || !props.role || !props.form) return

    let isMounted = true

    role.update(props.token, props.role.name, {
      description: props.form.description.value
    })
      .then(
        () => editingSaveSucceeded({
          id: Math.random(),
          message: translateString(props.translations, 'ROLE_UPDATED_SUCCESSFULLY')
        }),
        editingSaveFailed
      )
      .then(action => isMounted && props.dispatch(action))

    return () => { isMounted = false }
  }, [props.saving])

  return (
    <ActionDialog
      title='EDIT_ROLE'
      open={props.open}
      saving={props.saving}
      readOnly={false}
      onSave={() => props.dispatch(editingSaveRequested())}
      onCancel={() => props.dispatch(editingCanceled())}
    >
      {props.role && props.form && (
        <RoleForm
          {...props.form}
          name={initReadonly(props.role.name)}
          disabled={props.saving}
          translations={props.translations}
          onNameChange={() => {}}
          onDescriptionChange={(description) => props.dispatch(editingDescriptionUpdate(description))}
        />
      )}
      {props.saveResult && (
        <UpdateFailedHandler result={props.saveResult} />
      )}
    </ActionDialog>
  )
}

const selectHelperProps = (
  state: State,
  dispatch: React.Dispatch<Action>,
  session: Session,
  translations: Translations
): EditRoleHelperProps => {
  switch (state.roleEditing.type) {
    case RoleEditingType.InProgress:
      return {
        translations,
        dispatch,
        token: session.token,
        open: true,
        saving: remoteData.isLoading(state.roleEditing.save),
        saveResult: state.roleEditing.save,
        role: state.roleEditing.role,
        form: state.roleEditing.form
      }

    default:
      return {
        translations,
        dispatch,
        token: session.token,
        open: false,
        saving: false,
        saveResult: null,
        role: null,
        form: null
      }
  }
}

const UpdateFailedHandler: React.FC<{ result: RemoteData<Error, {}> }> = ({ result }) => {
  if (!remoteData.isFailed(result)) return null

  return (
    <Box mt={2}>
      {result.error instanceof ApiError
        ? (<ApiErrorMessage error={result.error} />)
        : (<ErrorMessage>UPDATE_GENERIC_ERROR</ErrorMessage>)
      }
    </Box>
  )
}
