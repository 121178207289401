import React from 'react'
import TopNavigationBarContext from '../../contexts/top-navigation-bar'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import adminIcon from '../../../assets/img/product-links/admin.svg'
import bimIcon from '../../../assets/img/product-links/bim.svg'
import cmsIcon from '../../../assets/img/product-links/cms.svg'
import damIcon from '../../../assets/img/product-links/dam.svg'
import mailIcon from '../../../assets/img/product-links/mail.svg'
import pimIcon from '../../../assets/img/product-links/pim.svg'
import { Theme, useTheme } from '@mui/material/styles'
import { SxProps } from '@mui/system/styleFunctionSx'
import csIcon from '../../../assets/img/product-links/cs.svg'

const TopNavigationBar: React.FC = () => {
  const topNavigationBars = React.useContext(TopNavigationBarContext)
  const theme = useTheme()

  const isBimApp = (appName: string): boolean => appName === 'Users'

  return (
    <Box data-testid='top-navigation-bar' display="flex" component="nav">
      {topNavigationBars.map(navigation =>
        <Link href={navigation.url}
          key={navigation.name}
          sx={isBimApp(navigation.name) ? linkActive(theme) : linkInactive(theme)}>
          <LinkIcon linkName={navigation.name}/>
          <Typography
            variant='body2'
            sx={{
              paddingLeft: theme.spacing(0.25),
              display: 'none',
              [theme.breakpoints.up('md')]: {
                display: 'block'
              }
            }}
          >{navigation.name}</Typography>
        </Link>)
      }
    </Box>)
}

export default TopNavigationBar

const LinkIcon: React.FC<{ linkName: string }> = ({ linkName }) => {
  let src = ''
  switch (linkName.toLowerCase()) {
    case 'admin':
      src = adminIcon
      break
    case 'users':
      src = bimIcon
      break
    case 'cms':
      src = cmsIcon
      break
    case 'dam':
      src = damIcon
      break
    case 'mail':
      src = mailIcon
      break
    case 'pim':
      src = pimIcon
      break
    case 'commercial sort':
      src = csIcon
      break
  }

  return src ? <img src={src} alt={linkName}/> : null
}

const linkInactive = (theme: Theme): SxProps<Theme> => ({
  color: 'white',
  display: 'inline-flex',
  alignItems: 'center',
  opacity: 0.7,
  padding: '3px 8px',
  borderRadius: theme.spacing(0.5),
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    textDecoration: 'none'
  },
  '@media (min-width: 960px)': {
    paddingRight: theme.spacing(2)
  }
})

const linkActive = (theme: Theme): SxProps<Theme> => ({
  color: 'white',
  display: 'inline-flex',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.dark,
  padding: '3px 8px',
  borderRadius: theme.spacing(0.5),
  '&:hover': {
    textDecoration: 'none'
  },
  [theme.breakpoints.up('md')]: {
    paddingRight: theme.spacing(2)
  }
})
