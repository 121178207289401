import React from 'react'
import remoteData, { RemoteData } from '../../../shared/remote-data'
import { UserModel } from '../../../api/user'
import * as userCreation from './user-creation'
import * as userDeletion from './user-deletion'
import * as userEditing from './user-editing'
import { Notification } from '../../../components/notifications'
import { UserDeletionType } from './user-deletion'
import * as manageRoles from './manage-roles'
import * as changePassword from './change-password'
export {
  State as UserCreation,
  UserCreationType,
  creationRequested,
  creationCanceled,
  creationNameUpdated,
  creationEmailUpdated,
  creationSaveRequested,
  creationSaveSucceeded,
  creationSaveFailed
} from './user-creation'

export {
  State as UserDeletion,
  UserDeletionType,
  deletionRequested,
  deletionCanceled,
  deletionConfirmed,
  deletionSucceeded,
  deletionFailed
} from './user-deletion'
export {
  State as UserRoles,
  ManageRolesType as UserRolesType,
  manageRolesRequested,
  manageRolesCanceled,
  manageRolesFetchRolesFailed,
  manageRolesFetchRolesSucceeded,
  manageRolesToggleRole
} from './manage-roles'
export {
  State as ChangePasswordState,
  ChangePasswordType,
  changePasswordRequested,
  changePasswordCanceled,
  changePasswordNewPasswordUpdated,
  changePasswordNewPasswordConfirmationUpdated,
  changePasswordSaveRequested,
  changePasswordSaveFailed,
  changePasswordSaveSucceeded
} from './change-password'
export {
  State as EditUserState,
  UserEditingType,
  editingCanceled,
  editingFullNameUpdated,
  editingRequested,
  editingSaveFailed,
  editingSaveRequested,
  editingSaveSucceeded,
  formToUpdateUserNameModel
} from './user-editing'

// STATE

export type State = {
  users: RemoteData<Error, UserModel[]>,
  searchInput: string,
  activeSearchExpression: string,
  userCreation: userCreation.State,
  userDeletion: userDeletion.State,
  userEditing: userEditing.State,
  manageRoles: manageRoles.State
  changePassword: changePassword.State
  notifications: Notification[]
}

export const initialState: State = {
  users: remoteData.loading(),
  searchInput: '',
  activeSearchExpression: '',
  userCreation: userCreation.initialState,
  userDeletion: userDeletion.initialState,
  userEditing: userEditing.initialState,
  manageRoles: manageRoles.initialState,
  changePassword: changePassword.initialState,
  notifications: []
}

// ACTIONS

const FETCH_USERS_SUCCEEDED = 'FETCH_USERS_SUCCEEDED'
const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED'
const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION'
const SEARCH_INPUT_UPDATED = 'SEARCH_INPUT_UPDATED'
const SEARCH_REQUESTED = 'SEARCH_REQUESTED'

export type Action =
  | { type: typeof FETCH_USERS_SUCCEEDED, payload: { users: UserModel[] } }
  | { type: typeof FETCH_USERS_FAILED, payload: { error: Error } }
  | { type: typeof DISMISS_NOTIFICATION, payload: { notificationId: number } }
  | { type: typeof SEARCH_INPUT_UPDATED, payload: { searchInput: string } }
  | { type: typeof SEARCH_REQUESTED }
  | userCreation.Action
  | userDeletion.Action
  | userEditing.Action
  | manageRoles.Action
  | changePassword.Action

export const fetchUsersSucceeded = (users: UserModel[]): Action => ({
  type: FETCH_USERS_SUCCEEDED,
  payload: {
    users
  }
})

export const fetchUsersFailed = (error: Error): Action => ({
  type: FETCH_USERS_FAILED,
  payload: {
    error
  }
})

export const dismissNotification = (notificationId: number): Action => ({
  type: DISMISS_NOTIFICATION,
  payload: {
    notificationId
  }
})

export const searchInputUpdated = (searchInput: string): Action => ({
  type: SEARCH_INPUT_UPDATED,
  payload: {
    searchInput
  }
})

export const searchRequested = (): Action => ({
  type: SEARCH_REQUESTED
})

// REDUCER

export const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case FETCH_USERS_SUCCEEDED:
      return { ...state, users: remoteData.succeeded(action.payload.users) }

    case FETCH_USERS_FAILED:
      return { ...state, users: remoteData.failed(action.payload.error) }

    case DISMISS_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications
          .filter(notification => notification.id !== action.payload.notificationId)
      }

    case SEARCH_INPUT_UPDATED:
      return {
        ...state,
        searchInput: action.payload.searchInput
      }

    case SEARCH_REQUESTED:
      return {
        ...state,
        activeSearchExpression: state.searchInput
      }

    case userCreation.CREATION_SAVE_SUCCEEDED:
      return {
        ...state,
        userCreation: userCreation.reducer(state.userCreation, action),
        users: remoteData.map(state.users, users => [
          action.payload.user,
          ...users
        ]),
        notifications: [
          ...state.notifications,
          action.payload.notification
        ]
      }

    case userDeletion.DELETION_SUCCEEDED: {
      const userDeletionState = state.userDeletion

      if (userDeletionState.type !== UserDeletionType.Deleting) return state

      return {
        ...state,
        userDeletion: userDeletion.reducer(state.userDeletion, action),
        users: remoteData.map(
          state.users,
          users => users.filter(user => user.id !== userDeletionState.user.id)
        ),
        notifications: [
          ...state.notifications,
          action.payload.notification
        ]
      }
    }

    case userEditing.EDITING_SAVE_SUCCEEDED: {
      const updatedUser = action.payload.user
      const notification = action.payload.notification

      return {
        ...state,
        userEditing: userEditing.reducer(state.userEditing, action),
        users: remoteData.map(
          state.users,
          users => users.map(
            user => user.id === updatedUser.id
              ? updatedUser
              : user
          )
        ),
        notifications: [
          ...state.notifications,
          notification
        ]
      }
    }

    case manageRoles.MANAGE_ROLES_SAVE_SUCCEEDED: {
      if (state.manageRoles.type !== manageRoles.ManageRolesType.InProgress) return state
      const { user, form, allRoles } = state.manageRoles
      if (!remoteData.isSucceeded(allRoles)) return state

      const updatedUser: UserModel = {
        ...user,
        userRoles: allRoles.data.filter(role => form.roleNames.value.includes(role.name))
      }

      return {
        ...state,
        manageRoles: manageRoles.reducer(state.manageRoles, action),
        users: remoteData.map(
          state.users,
          users => users.map(u => u.id === updatedUser.id ? updatedUser : u)
        ),
        notifications: [
          ...state.notifications,
          action.payload
        ]
      }
    }

    case changePassword.CHANGE_PASSWORD_SAVE_SUCCEEDED:
      return {
        ...state,
        changePassword: changePassword.reducer(state.changePassword, action),
        notifications: [
          ...state.notifications,
          action.payload.notification
        ]
      }

    default:
      return {
        ...state,
        userCreation: userCreation.reducer(
          state.userCreation,
          action as userCreation.Action
        ),
        userDeletion: userDeletion.reducer(
          state.userDeletion,
          action as userDeletion.Action
        ),
        userEditing: userEditing.reducer(
          state.userEditing,
          action as userEditing.Action
        ),
        manageRoles: manageRoles.reducer(
          state.manageRoles,
          action as manageRoles.Action
        ),
        changePassword: changePassword.reducer(
          state.changePassword,
          action as changePassword.Action
        )
      }
  }
}

// CONTEXT

export type Store = {
  state: State,
  dispatch: React.Dispatch<Action>
}

export const StoreContext = React.createContext<Store>({
  state: initialState,
  dispatch: () => { }
})
