import React from 'react'
import {
  managePermissionsTogglePermission,
  State,
  StoreContext, managePermissionsFetchPermissionsSucceeded, managePermissionsFetchPermissionsFailed
} from './store'
import remoteData, { RemoteData, RemoteDataType } from '../../shared/remote-data'
import { Translations } from '../../api/language'
import SessionContext, { Session } from '../../contexts/session'
import {
  managePermissionsCanceled,
  managePermissionsSaveFailed,
  managePermissionsSaveRequested,
  managePermissionsSaveSucceeded,
  ManagePermissionsType
} from './store/manage-permissions'
import {
  RoleModel,
  SetRolePermissionsModel,
  updatePermissions,
  getAllPermissions,
  getRolePermissions
} from '../../api/role'
import TranslationsContext from '../../contexts/translations'
import { Form } from '../../shared/form'
import { translateString } from '../../components/translate'
import ManageRelations, { OptionModel } from '../../components/manage-relations'

const ManagePermissions: React.FC = () => {
  const { state } = React.useContext(StoreContext)
  const session = React.useContext(SessionContext)
  const translations = React.useContext(TranslationsContext)

  return (
    <ManagePermissionsHelper
      {...selectHelperProps(
        state,
        session,
        translations
      )}
    />
  )
}

export default ManagePermissions

type ManagePermissionsHelperProps = {
  open: boolean
  role: null | RoleModel
  options: RemoteData<Error, OptionModel[]>
  form: null | Form<SetRolePermissionsModel>
  saving: boolean
  saveResult: RemoteData<Error, {}>
  token: string
  translations: Translations
  readOnly: boolean
}

const ManagePermissionsHelper: React.FC<ManagePermissionsHelperProps> = (props) => {
  const { dispatch } = React.useContext(StoreContext)

  React.useEffect(() => {
    if (props.options.type !== RemoteDataType.Loading || !props.role) return
    let isMounted = true

    Promise.all([
      getAllPermissions(props.token),
      getRolePermissions(props.token, props.role.name)])
      .then(
        managePermissionsFetchPermissionsSucceeded,
        managePermissionsFetchPermissionsFailed
      ).then(action => isMounted && dispatch(action))
    return () => { isMounted = false }
  }, [props.options.type])

  React.useEffect(() => {
    if (!props.saving || !props.role || !props.form) return
    let isMounted = true

    updatePermissions(props.token, props.role.name, {
      permissionIds: props.form.permissionIds.value
    })
      .then(
        () => managePermissionsSaveSucceeded({
          id: Math.random(),
          message: translateString(
            props.translations,
            'ROLE_UPDATED_SUCCESSFULLY'
          )
        }),
        managePermissionsSaveFailed
      )
      .then(action => isMounted && dispatch(action))

    return () => { isMounted = false }
  }, [props.saving])

  return (
    <ManageRelations
      open={props.open}
      title={props.role ? props.role.name : ''}
      description={props.role && props.role.description ? props.role.description : ''}
      relationTitle='PERMISSIONS'
      readOnly={props.readOnly}
      onSave={() => dispatch(managePermissionsSaveRequested())}
      onCancel={() => dispatch(managePermissionsCanceled())}
      onChange={(id, checked) => dispatch(managePermissionsTogglePermission(id, checked))}
      options={props.options}
      saveResult={props.saveResult}
    />
  )
}

const selectHelperProps = (
  state: State,
  session: Session,
  translations: Translations
): ManagePermissionsHelperProps => {
  switch (state.managePermissions.type) {
    case ManagePermissionsType.InProgress: {
      const form = state.managePermissions.form
      return {
        translations,
        token: session.token,
        open: true,
        role: state.managePermissions.role,
        options: remoteData.map(state.managePermissions.allPermissions,
          (permissions) => permissions.map(permission => ({
            id: permission.id,
            name: permission.name,
            selected: form.permissionIds.value.includes(permission.id)
          }))
        ),
        form,
        saving: remoteData.isLoading(state.managePermissions.save),
        saveResult: state.managePermissions.save,
        readOnly: !session.me.permissions.manage
      }
    }

    default:
      return {
        translations,
        token: session.token,
        open: false,
        role: null,
        options: remoteData.notAsked(),
        form: null,
        saving: false,
        saveResult: remoteData.notAsked(),
        readOnly: !session.me.permissions.manage
      }
  }
}
