import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Callback from '../pages/callback'
import NotFound from '../pages/not-found'
import SessionLoader from './session-loader'
import Unauthorized from '../pages/unauthorized'
import Roles from '../pages/roles'
import Authorize from './authorize'
import * as url from '../shared/url'
import Users from '../pages/users'
import SilentRenew from '../pages/silent-renew'

const Routes: React.FC = () => (
  <Switch>
    <Route exact path={url.callback}>
      <Callback />
    </Route>
    <Route exact path={url.silentRenew}>
      <SilentRenew />
    </Route>
    <Route exact path={url.unauthorized}>
      <Unauthorized />
    </Route>
    <Route path='*'>
      <SessionLoader>
        <Authorize permissions={['view']}>
          <ProtectedRoutes />
        </Authorize>
      </SessionLoader>
    </Route>
  </Switch>
)

const ProtectedRoutes: React.FC = () => (
  <Switch>
    <Route exact path={url.users}>
      <Users />
    </Route>
    <Route exact path={url.roles}>
      <Roles />
    </Route>
    <Route path='*'>
      <NotFound />
    </Route>
  </Switch>
)

export default Routes
