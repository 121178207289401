import React from 'react'
import AuthorizedHeader from '../../components/authorized-header'
import Fade from '@mui/material/Fade'
import ListRoles from './list-roles'
import { reducer, initialState, StoreContext, creationRequested, dismissNotification } from './store'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Translate from '../../components/translate'
import Typography from '@mui/material/Typography'
import Notifications from '../../components/notifications'
import Button from '@mui/material/Button'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AddRole from './add-role'
import EditRole from './edit-role'
import ManageUsers from './manage-users'
import ManagePermissions from './manage-permissions'
import SessionContext from '../../contexts/session'
import { useTheme } from '@mui/material/styles'

const Roles: React.FC = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const store = React.useMemo(() => ({ state, dispatch }), [state, dispatch])
  const session = React.useContext(SessionContext)
  const theme = useTheme()

  return (
    <div data-testid='roles'>
      <AuthorizedHeader />
      <Fade in={true}>
        <Container maxWidth={false}
          sx={{
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            [theme.breakpoints.up('sm')]: {
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2)
            },
            [theme.breakpoints.up('lg')]: {
              paddingLeft: theme.spacing(10.625),
              paddingRight: theme.spacing(10.625)
            }
          }}
        >
          <Box display='flex' justifyContent='space-between' mt={4}>
            <Typography variant='h5' component='h2'>
              <Translate>ROLES</Translate>
            </Typography>
            {session.me.permissions.manage && (
              <Button
                variant='text'
                startIcon={<AddCircleIcon />}
                onClick={() => dispatch(creationRequested())}
                sx={{
                  padding: theme.spacing(0.75, 1.5),
                  '&:hover': {
                    color: '#007899'
                  }
                }}
              >
                <Typography color="primary" variant="button">
                  <Translate>ADD_ROLE</Translate>
                </Typography>
              </Button>
            )}
          </Box>
          <StoreContext.Provider value={store}>
            <ListRoles />
            <AddRole />
            <EditRole />
            <ManageUsers />
            <ManagePermissions />
            <Notifications
              notifications={state.notifications}
              onDismissNotification={ (id) => dispatch(dismissNotification(id)) }
            />
          </StoreContext.Provider>
        </Container>
      </Fade>
    </div>
  )
}

export default Roles
