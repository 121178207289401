import React from 'react'
import AuthorizedHeader from '../components/authorized-header'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Translate, { translateString } from '../components/translate'
import TranslationsContext from '../contexts/translations'
import notFound from '../../assets/img/not-found.svg'
import Button from '@mui/material/Button'
import ArrowForward from '@mui/icons-material/ArrowForward'
import * as url from '../shared/url'
import RouteLink from '../components/route-link'

const NotFound: React.FC = () => {
  const translations = React.useContext(TranslationsContext)

  return (
    <div data-testid='not-found'>
      <AuthorizedHeader />
      <Box display='flex' flexDirection='column' alignItems='center' mt={8} >
        <Box mb={2}><img src={notFound} alt={translateString(translations, 'UNAUTHORIZED')} /></Box>
        <Typography variant='h4' component='h2' color='primary'>
          <Translate>NOT_FOUND</Translate>
        </Typography>
        <Box component='p' textAlign='center'>
          <Box component='span' display='block'><Translate>NOT_FOUND_MESSAGE_1</Translate></Box>
          <Box component='span' display='block'><Translate>NOT_FOUND_MESSAGE_2</Translate></Box>
        </Box>
        <Box component='p' textAlign='center' mt={0} mb={4}>
          <Box component='span' display='block'><Translate>NOT_FOUND_MESSAGE_3</Translate></Box>
        </Box>
        <RouteLink to={url.users}>
          <Button
            variant='contained'
            color='primary'
            endIcon={<ArrowForward />}
          >
            <Translate>NOT_FOUND_REDIRECT</Translate>
          </Button>
        </RouteLink>
      </Box>
    </div>
  )
}

export default NotFound
