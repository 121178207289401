import React from 'react'
import Box from '@mui/material/Box'
import TranslationsContext from '../contexts/translations'
import UserManagerContext from '../contexts/user-manager'
import Translate, { translateString } from '../components/translate'
import Header from '../components/header'
import unauthorized from '../../assets/img/unauthorized.svg'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ArrowForward from '@mui/icons-material/ArrowForward'

const Unauthorized: React.FC = () => {
  const userManager = React.useContext(UserManagerContext)
  const translations = React.useContext(TranslationsContext)
  const logout = () => userManager.instance.signoutRedirect()

  return (
    <div data-testid='unauthorized'>
      <Header />
      <Box display='flex' flexDirection='column' alignItems='center' mt={8} >
        <Box mb={2}><img src={unauthorized} alt={translateString(translations, 'UNAUTHORIZED')} /></Box>
        <Typography variant='h4' component='h2' color='primary'>
          <Translate>UNAUTHORIZED</Translate>
        </Typography>
        <Box component='p' textAlign='center' mb={4}>
          <Box component='span' display='block'><Translate>UNAUTHORIZED_MESSAGE_1</Translate></Box>
          <Box component='span' display='block'><Translate>UNAUTHORIZED_MESSAGE_2</Translate></Box>
        </Box>
        <Button
          variant='contained'
          color='primary'
          endIcon={<ArrowForward />}
          onClick={logout}
        >
          <Translate>UNAUTHORIZED_LOGOUT</Translate>
        </Button>
      </Box>
    </div>
  )
}

export default Unauthorized
