// FORM

export type Form<T> = {
  [K in keyof T]: Field<T[K]>
}

// FIELD

export type Field<T> =
  | FieldInvalid<T>
  | FieldValid<T>

export type FieldInvalid<T> = {
  type: FieldType.Invalid
  value: T
  pristine: boolean
  readonly: boolean
  error: string
}

export type FieldValid<T> = {
  type: FieldType.Valid
  pristine: boolean
  readonly: boolean
  value: T
}

export enum FieldType {
  Invalid = 'Invalid',
  Valid = 'Valid'
}

export const initInvalid = <T>(
  value: T,
  error: string
): Field<T> => ({
    type: FieldType.Invalid,
    value,
    pristine: true,
    readonly: false,
    error
  })

export const initValid = <T>(
  value: T
): Field<T> => ({
    type: FieldType.Valid,
    pristine: true,
    readonly: false,
    value
  })

export const initValidated = <T>(
  value: T,
  validator: Validator<T>
): Field<T> =>
    toValidated(initValid(value), value, validator, false)

export const initReadonly = <T>(
  value: T
): Field<T> => ({
    type: FieldType.Valid,
    pristine: true,
    readonly: true,
    value
  })

export const toInvalid = <T>(field: Field<T>, value: T, error: string, setDirty: boolean = true): Field<T> =>
  field.readonly
    ? field
    : {
        ...field,
        type: FieldType.Invalid,
        value,
        error,
        pristine: field.pristine && !setDirty
      }

export const toValid = <T>(field: Field<T>, value: T, setDirty: boolean = true): Field<T> =>
  field.readonly
    ? field
    : {
        ...field,
        type: FieldType.Valid,
        value,
        pristine: field.pristine && !setDirty
      }

export const toDirty = <T>(field: Field<T>): Field<T> =>
  field.readonly
    ? field
    : {
        ...field,
        pristine: false
      }

export const toValidated = <T>(
  field: Field<T>,
  value: T,
  validator: Validator<T>,
  setDirty: boolean = true
): Field<T> => {
  const error = validator(value)
  return error === null
    ? toValid(field, value, setDirty)
    : toInvalid(field, value, error, setDirty)
}

export type Validator<T> =
  (value: T) => (null | string)

export const isInvalid = <T>(field: Field<T>): field is FieldInvalid<T> =>
  field.type === FieldType.Invalid

export const isValid = <T>(field: Field<T>): field is FieldValid<T> =>
  field.type === FieldType.Valid

export const isDirty = <T>(field: Field<T>): field is FieldValid<T> =>
  !field.pristine
