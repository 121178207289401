import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/InfoRounded'
import LockIcon from '@mui/icons-material/Lock'
import React from 'react'
import { SxProps } from '@mui/system/styleFunctionSx'

import * as Debounce from '../../../shared/debounce'
import { getAll as getAllUsers, UserModel } from '../../api/user'
import ErrorRow from '../../components/error-row'
import LoadingRow from '../../components/loading-row'
import StyledTableCell from '../../components/table-cell'
import Translate, { translateString } from '../../components/translate'
import SessionContext from '../../contexts/session'
import TranslationsContext from '../../contexts/translations'
import UserManagerContext from '../../contexts/user-manager'
import remoteData from '../../shared/remote-data'
import ChangePassword from './change-password'
import DeleteConfirmation from './delete-confirmation'
import DeleteSelfConfirmation from './delete-self-confirmation'
import {
  changePasswordRequested,
  deletionRequested,
  editingRequested,
  fetchUsersFailed,
  fetchUsersSucceeded,
  manageRolesRequested,
  searchInputUpdated,
  searchRequested,
  StoreContext
} from './store'
import { Theme, useTheme } from '@mui/material/styles'
import TableHeadCell from '../../components/table-head-cell'

const ListUsers: React.FC = () => {
  const { state, dispatch } = React.useContext(StoreContext)
  const session = React.useContext(SessionContext)
  const translations = React.useContext(TranslationsContext)

  Debounce.useDebounce(
    () => { dispatch(searchRequested()) },
    [state.searchInput],
    500
  )

  React.useEffect(() => {
    let isMounted = true

    getAllUsers(session.token)
      .then(fetchUsersSucceeded, fetchUsersFailed)
      .then(action => isMounted && dispatch(action))

    return () => { isMounted = false }
  }, [])

  return (
    <>
      <Box pb={3} pt={1}>
        <TextField
          id='search-input'
          variant='standard'
          label={<Translate>SEARCH</Translate>}
          value={state.searchInput}
          onChange={event => dispatch(searchInputUpdated(event.target.value))}
        />
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell><Translate>NAME</Translate></TableHeadCell>
              <TableHeadCell><Translate>EMAIL</Translate></TableHeadCell>
              <TableHeadCell><Translate>ROLES</Translate></TableHeadCell>
              <TableHeadCell></TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {remoteData.match(state.users, {
              notAsked () { return <LoadingRow colSpan={4} /> },
              loading () { return <LoadingRow colSpan={4} /> },
              failed () { return <ErrorRow colSpan={4} /> },
              succeeded (users) {
                return (
                  <UserRows
                    users={filterUsers(users, state.activeSearchExpression, `(${translateString(translations, 'USER_NAME_UNASSIGNED')})`)}
                    readOnly={!session.me.permissions.manage}
                  />
                )
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteConfirmation />
      <DeleteSelfConfirmation />
      <ChangePassword />
    </>
  )
}

export default ListUsers

const UserRows: React.FC<{
  users: UserModel[]
  readOnly: boolean
}> = ({ users, readOnly }) => {
  const { dispatch } = React.useContext(StoreContext)
  const translations = React.useContext(TranslationsContext)
  const userManager = React.useContext(UserManagerContext)
  const theme = useTheme()

  if (users.length === 0) {
    return (
      <TableRow>
        <TableCell colSpan={4}>
          <Box display='flex' alignItems='center'>
            <Box component={InfoIcon} mr={1} />
            <Translate>NO_ITEMS_AVAILABLE</Translate>
          </Box>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      {users.map(user => {
        const nameForDisplay = user.fullName === null
          ? `(${translateString(translations, 'USER_NAME_UNASSIGNED')})`
          : user.fullName

        return (
          <TableRow
            key={user.id}
            data-testid={`user-${user.id}`}
            hover
            sx={{
              '&:hover [data-action]': {
                visibility: 'visible'
              }
            }}
          >
            <StyledTableCell
              sx={{
                color: theme.palette.primary.main,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '16ch'
              }}
              title={nameForDisplay}
            >
              {nameForDisplay}
            </StyledTableCell>
            <StyledTableCell sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{user.email}</StyledTableCell>
            <StyledTableCell sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{user.userRoles.map(role => role.name).join(', ')}</StyledTableCell>
            <StyledTableCell sx={{ padding: theme.spacing(0, 1, 0, 0) }} align='right'>
              <Tooltip title={translateString(translations, 'EDIT')}>
                <IconButton
                  data-testid='edit'
                  data-action
                  sx={userActionStyles(theme)}
                  onClick={() => dispatch(editingRequested(user))}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              {!readOnly && (
                <Tooltip title={translateString(translations, 'DELETE')}>
                  <IconButton
                    data-testid='delete'
                    data-action
                    onClick={() => dispatch(deletionRequested(user))}
                    sx={{
                      visibility: 'hidden',
                      padding: theme.spacing(1),
                      '&:hover': {
                        color: '#EB5757'
                      }
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={translateString(translations, 'ADD_ROLES')}>
                <IconButton
                  data-testid='add_roles'
                  data-action
                  onClick={() => dispatch(manageRolesRequested(user))}
                  sx={userActionStyles(theme)}
                >
                  <AddCircleRoundedIcon />
                </IconButton>
              </Tooltip>
              {userManager.enableLocalLogin && (
                <Tooltip title={translateString(translations, 'CHANGE_PASSWORD')}>
                  <IconButton
                    data-testid='change_password'
                    data-action
                    onClick={() => dispatch(changePasswordRequested(user))}
                    sx={userActionStyles(theme)}
                  >
                    <LockIcon />
                  </IconButton>
                </Tooltip>
              )}
            </StyledTableCell>
          </TableRow>
        )
      })}
    </>
  )
}

const userActionStyles = (theme: Theme): SxProps<Theme> => ({
  visibility: 'hidden',
  padding: theme.spacing(1),
  '&:hover': {
    color: '#007899'
  }
})

const filterUsers = (users: UserModel[], expression: string, noNameTranslation: string): UserModel[] => {
  const normalizedExpression = expression.toLowerCase()

  return users.filter(
    user =>
      (user.fullName ?? noNameTranslation).toLowerCase().includes(normalizedExpression) ||
      user.email.toLowerCase().includes(normalizedExpression)
  )
}
