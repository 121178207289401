import React from 'react'
import CultureSelect from './culture-select'
import remoteData, { RemoteData } from '../../shared/remote-data'
import { Culture, getCultures } from '../../api/language'
import * as preferences from '../../shared/preferences'
import LocationContext from '../../../shared/contexts/location'
import UserMenu from './user-menu'
import Header from '../header'
import InternalNavigation from './internal-navigation'
import TopNavigationBar from './top-navigation-bar'

// STATE

type State = {
  cultures: RemoteData<Error, Culture[]>
}

const initialState: State = {
  cultures: remoteData.notAsked()
}

const FETCH_CULTURES_REQUESTED = 'FETCH_CULTURES_REQUESTED'
const FETCH_CULTURES_SUCCEEDED = 'FETCH_CULTURES_SUCCEEDED'
const FETCH_CULTURES_FAILED = 'FETCH_CULTURES_FAILED'

type Action =
  | { type: typeof FETCH_CULTURES_REQUESTED }
  | { type: typeof FETCH_CULTURES_SUCCEEDED, payload: Culture[] }
  | { type: typeof FETCH_CULTURES_FAILED, payload: Error }

const fetchCulturesRequested = (): Action => ({
  type: FETCH_CULTURES_REQUESTED
})

const fetchCulturesSucceeded = (cultures: Culture[]): Action => ({
  type: FETCH_CULTURES_SUCCEEDED,
  payload: cultures
})

const fetchCulturesFailed = (error: Error): Action => ({
  type: FETCH_CULTURES_FAILED,
  payload: error
})

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case FETCH_CULTURES_REQUESTED:
      return { ...state, cultures: remoteData.loading() }

    case FETCH_CULTURES_SUCCEEDED:
      return { ...state, cultures: remoteData.succeeded(action.payload) }

    case FETCH_CULTURES_FAILED:
      return { ...state, cultures: remoteData.failed(action.payload) }

    default:
      return state
  }
}

// COMPONENT

const AuthorizedHeader: React.FC = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const location = React.useContext(LocationContext)

  const loadCultures = () => {
    dispatch(fetchCulturesRequested())

    getCultures()
      .then(cultures => dispatch(fetchCulturesSucceeded(cultures)))
      .catch(error => dispatch(fetchCulturesFailed(error)))
  }

  const cultureSelected = (culture: Culture) => {
    preferences.setPreferredCulture(culture.name)
    location.reload()
  }

  return (
    <Header>
      {{
        topLeft: (
          <>
            <TopNavigationBar />
          </>
        ),
        topRight: (
          <>
            <CultureSelect
              cultures={state.cultures}
              onLoad={loadCultures}
              onSelect={cultureSelected}
            />
            <UserMenu />
          </>
        ),
        middleRight: (
          <>
            <InternalNavigation />
          </>
        )
      }}
    </Header>
  )
}

export default AuthorizedHeader
