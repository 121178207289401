import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import React from 'react'

import * as UserApi from '../../api/user'
import ActionDialog from '../../components/action-dialog'
import ApiResultErrorMessage from '../../components/api-result-error-message'
import Translate, { translateString } from '../../components/translate'
import SessionContext from '../../contexts/session'
import remoteData from '../../shared/remote-data'
import { StoreContext, UserEditingType } from './store'
import * as Store from './store/user-editing'
import TranslationsContext from '../../contexts/translations'

const EditUser: React.FC = () => {
  const { state } = React.useContext(StoreContext)

  switch (state.userEditing.type) {
    case UserEditingType.NotStarted:
      return null

    case UserEditingType.InProgress:
      return <EditUserInProgress {...state.userEditing} />
  }
}

export default EditUser

const EditUserInProgress: React.FC<Store.UserEditingInProgress> = (props) => {
  const { dispatch } = React.useContext(StoreContext)
  const session = React.useContext(SessionContext)
  const translations = React.useContext(TranslationsContext)
  const canEdit = session.me.permissions.manage
  const saving = remoteData.isLoading(props.save)

  React.useEffect(() => {
    if (!saving) return

    let isMounted = true

    const updateModel = Store.formToUpdateUserNameModel(props.form)

    UserApi.updateFullName(
      session.token,
      props.user.id,
      updateModel
    )
      .then(
        () => Store.editingSaveSucceeded(
          { ...props.user, fullName: updateModel.fullName },
          { id: Math.random(), message: translateString(translations, 'USER_UPDATED_SUCCESSFULLY') }
        ),
        Store.editingSaveFailed
      )
      .then(
        action => isMounted && dispatch(action)
      )

    return () => { isMounted = false }
  }, [saving])

  return (
    <ActionDialog
      title='EDIT_USER'
      open={true}
      readOnly={!canEdit}
      saving={saving}
      onSave={() => dispatch(Store.editingSaveRequested())}
      onCancel={() => dispatch(Store.editingCanceled())}
    >
      <FormControl fullWidth>
        <TextField
          variant='filled'
          label={<Translate>FULLNAME</Translate>}
          id='user-name'
          autoComplete='off'
          disabled={!canEdit || saving}
          value={props.form.fullName.value}
          inputProps={{ maxLength: 255 }}
          onChange={event => dispatch(Store.editingFullNameUpdated(event.target.value))}
        />
      </FormControl>
      <ApiResultErrorMessage
        result={props.save}
        fallbackMessage={<Translate>UPDATE_GENERIC_ERROR</Translate>}
      />
    </ActionDialog>
  )
}
