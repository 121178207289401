import React from 'react'
import { UpdateUserFullNameModel, UserModel } from '../../../api/user'
import * as Form from '../../../shared/form'
import remoteData, { RemoteData } from '../../../shared/remote-data'
import { Notification } from '../../../components/notifications'

// STATE

export type State =
  | UserEditingNotStarted
  | UserEditingInProgress

export type UserEditingNotStarted = {
  type: UserEditingType.NotStarted
}

export type UserEditingInProgress = {
  type: UserEditingType.InProgress
  user: UserModel
  form: EditUserForm
  save: RemoteData<Error, {}>
}

export enum UserEditingType {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress'
}

export type EditUserForm = Form.Form<{
  fullName: string
}>

export const initialState: State = {
  type: UserEditingType.NotStarted
}

// ACTION

export const EDITING_REQUESTED = 'EDITING_REQUESTED'
export const EDITING_CANCELED = 'EDITING_CANCELED'
export const EDITING_FULL_NAME_UPDATED = 'EDITING_FULL_NAME_UPDATED'
export const EDITING_SAVE_REQUESTED = 'EDITING_SAVE_REQUESTED'
export const EDITING_SAVE_FAILED = 'EDITING_SAVE_FAILED'
export const EDITING_SAVE_SUCCEEDED = 'EDITING_SAVE_SUCCEEDED'

export type Action =
  | { type: typeof EDITING_REQUESTED, payload: { user: UserModel } }
  | { type: typeof EDITING_CANCELED }
  | { type: typeof EDITING_FULL_NAME_UPDATED, payload: { fullName: string } }
  | { type: typeof EDITING_SAVE_REQUESTED }
  | { type: typeof EDITING_SAVE_FAILED, payload: { error: Error } }
  | { type: typeof EDITING_SAVE_SUCCEEDED, payload: { user: UserModel, notification: Notification } }

export const editingRequested = (user: UserModel): Action => ({
  type: EDITING_REQUESTED,
  payload: { user }
})

export const editingCanceled = (): Action => ({
  type: EDITING_CANCELED
})

export const editingFullNameUpdated = (fullName: string): Action => ({
  type: EDITING_FULL_NAME_UPDATED,
  payload: { fullName }
})

export const editingSaveRequested = (): Action => ({
  type: EDITING_SAVE_REQUESTED
})

export const editingSaveFailed = (error: Error): Action => ({
  type: EDITING_SAVE_FAILED,
  payload: { error }
})

export const editingSaveSucceeded = (user: UserModel, notification: Notification): Action => ({
  type: EDITING_SAVE_SUCCEEDED,
  payload: { user, notification }
})

// REDUCER

export const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case EDITING_REQUESTED:
      return {
        ...state,
        type: UserEditingType.InProgress,
        user: action.payload.user,
        form: {
          fullName: Form.initValid(action.payload.user.fullName ?? '')
        },
        save: remoteData.notAsked()
      }

    case EDITING_SAVE_SUCCEEDED:
    case EDITING_CANCELED:
      return {
        type: UserEditingType.NotStarted
      }

    case EDITING_FULL_NAME_UPDATED:
      return updateForm(state, form => ({
        ...form,
        fullName: Form.toValid(form.fullName, action.payload.fullName)
      }))

    case EDITING_SAVE_REQUESTED:
      return updateSave(state, () => remoteData.loading())

    case EDITING_SAVE_FAILED:
      return updateSave(state, () => remoteData.failed(action.payload.error))

    default:
      return state
  }
}

const updateForm = (
  state: State,
  update: (form: EditUserForm) => EditUserForm
): State => {
  switch (state.type) {
    case UserEditingType.InProgress:
      return {
        ...state,
        form: update(state.form)
      }

    default:
      return state
  }
}

const updateSave = (
  state: State,
  update: (save: RemoteData<Error, {}>) => RemoteData<Error, {}>
): State => {
  switch (state.type) {
    case UserEditingType.InProgress:
      return {
        ...state,
        save: update(state.save)
      }

    default:
      return state
  }
}

// UTILITY

export const formToUpdateUserNameModel = (form: EditUserForm): UpdateUserFullNameModel => ({
  fullName: /^\s*$/.test(form.fullName.value) ? null : form.fullName.value
})
