import { Form, initInvalid, initValid, toInvalid, toValid, toDirty, isInvalid } from '../../../shared/form'
import React from 'react'
import { CreateRoleModel, RoleModel } from '../../../api/role'
import remoteData, { RemoteData } from '../../../shared/remote-data'
import { Notification } from '../../../components/notifications'

// STATE

export type State =
  | { type: RoleCreationType.NotStarted }
  | {
      type: RoleCreationType.InProgress
      form: Form<CreateRoleModel>
      save: RemoteData<Error, {}>
    }

export enum RoleCreationType {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress'
}

export const initialState: State = {
  type: RoleCreationType.NotStarted
}

// ACTIONS

export const CREATION_REQUESTED = 'CREATION_REQUESTED'
export const CREATION_CANCELED = 'CREATION_CANCELED'
export const CREATION_NAME_UPDATED = 'CREATION_NAME_UPDATED'
export const CREATION_DESCRIPTION_UPDATED = 'CREATION_DESCRIPTION_UPDATED'
export const CREATION_SAVE_REQUESTED = 'CREATION_SAVE_REQUESTED'
export const CREATION_SAVE_FAILED = 'CREATION_SAVE_FAILED'
export const CREATION_SAVE_SUCCEEDED = 'CREATION_SAVE_SUCCEEDED'

export type Action =
  | { type: typeof CREATION_REQUESTED }
  | { type: typeof CREATION_CANCELED }
  | { type: typeof CREATION_NAME_UPDATED, payload: string }
  | { type: typeof CREATION_DESCRIPTION_UPDATED, payload: string }
  | { type: typeof CREATION_SAVE_REQUESTED }
  | { type: typeof CREATION_SAVE_FAILED, payload: Error }
  | { type: typeof CREATION_SAVE_SUCCEEDED, payload: { role: RoleModel, notification: Notification } }

export const creationRequested = (): Action => ({
  type: CREATION_REQUESTED
})

export const creationCanceled = (): Action => ({
  type: CREATION_CANCELED
})

export const creationSaveRequested = (): Action => ({
  type: CREATION_SAVE_REQUESTED
})

export const creationSaveFailed = (error: Error): Action => ({
  type: CREATION_SAVE_FAILED,
  payload: error
})

export const creationSaveSucceeded = (role: RoleModel, notification: Notification): Action => ({
  type: CREATION_SAVE_SUCCEEDED,
  payload: {
    role,
    notification
  }
})

export const creationNameUpdated = (value: string): Action => ({
  type: CREATION_NAME_UPDATED,
  payload: value
})

export const creationDescriptionUpdated = (value: string): Action => ({
  type: CREATION_DESCRIPTION_UPDATED,
  payload: value
})

// REDUCER

export const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case CREATION_REQUESTED:
      return {
        ...state,
        type: RoleCreationType.InProgress,
        form: {
          name: initInvalid('', ROLE_NAME_IS_REQUIRED),
          description: initValid('')
        },
        save: remoteData.notAsked()
      }

    case CREATION_CANCELED:
      return {
        ...state,
        type: RoleCreationType.NotStarted
      }

    case CREATION_SAVE_REQUESTED:
      return updateForm(
        updateSave(state, (_save, form) =>
          isInvalid(form.name) || isInvalid(form.description)
            ? remoteData.notAsked()
            : remoteData.loading()
        ),
        form => ({
          ...form,
          name: toDirty(form.name),
          description: toDirty(form.description)
        })
      )

    case CREATION_SAVE_FAILED:
      return updateSave(state, () => remoteData.failed(action.payload))

    case CREATION_SAVE_SUCCEEDED:
      return {
        type: RoleCreationType.NotStarted
      }

    case CREATION_NAME_UPDATED:
      return updateForm(state, form => ({
        ...form,
        name: /^[a-zA-Z0-9\-._@+]+$/.test(action.payload)
          ? toValid(form.name, action.payload)
          : toInvalid(form.name, action.payload, ROLENAME_ILLEGAL_CHAR_0_1)
      }))

    case CREATION_DESCRIPTION_UPDATED:
      return updateForm(state, form => ({
        ...form,
        description: toValid(form.description, action.payload)
      }))

    default:
      return state
  }
}

const updateForm = (
  state: State,
  update: (form: Form<CreateRoleModel>) => Form<CreateRoleModel>
): State => {
  switch (state.type) {
    case RoleCreationType.InProgress:
      return {
        ...state,
        form: update(state.form)
      }

    default:
      return state
  }
}

const updateSave = (
  state: State,
  update: (save: RemoteData<Error, {}>, form: Form<CreateRoleModel>) => RemoteData<Error, {}>
): State => {
  switch (state.type) {
    case RoleCreationType.InProgress:
      return {
        ...state,
        save: update(state.save, state.form)
      }

    default:
      return state
  }
}

// MESSAGES

const ROLE_NAME_IS_REQUIRED = 'ROLE_NAME_IS_REQUIRED'
const ROLENAME_ILLEGAL_CHAR_0_1 = 'ROLENAME_ILLEGAL_CHAR_0_1'
