import React from 'react'
import TranslationsContext from '../contexts/translations'
import { Translations } from '../api/language'

export type Props = {
  children: string
}

const Translate: React.FC<Props> = (props) => {
  const translations = React.useContext(TranslationsContext)
  return <>{translateString(translations, props.children)}</>
}

export default Translate

export const translateString = (translations: Translations, key: string): string => {
  return key in translations.resources
    ? translations.resources[key]
    : key
}
