import React from 'react'
import { Redirect } from 'react-router-dom'
import SessionContext from '../contexts/session'
import { Permissions } from '../api/me'
import * as url from '../shared/url'

export type Props = {
  permissions: Array<keyof Permissions>
}

const Authorize: React.FC<Props> = (props) => {
  const session = React.useContext(SessionContext)

  return props.permissions.every(permission => session.me.permissions[permission])
    ? <>{props.children}</>
    : <Redirect to={url.unauthorized} />
}

export default Authorize
