import React from 'react'
import { RoleModel, SetRolePermissionsModel, PermissionModel, RolePermissionModel } from '../../../api/role'
import remoteData, { RemoteData } from '../../../shared/remote-data'
import { Form, initValid, toValid } from '../../../shared/form'
import { Notification } from '../../../components/notifications'

// STATE

export type State =
  | { type: ManagePermissionsType.NotStarted }
  | {
      type: ManagePermissionsType.InProgress
      role: RoleModel
      allPermissions: RemoteData<Error, PermissionModel[]>
      form: Form<SetRolePermissionsModel>
      save: RemoteData<Error, {}>
    }

export enum ManagePermissionsType {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress'
}

export const initialState: State = {
  type: ManagePermissionsType.NotStarted
}

// ACTION

export const MANAGE_PERMISSIONS_REQUESTED = 'MANAGE_PERMISSIONS_REQUESTED'
export const MANAGE_PERMISSIONS_CANCELED = 'MANAGE_PERMISSIONS_CANCELED'
export const MANAGE_PERMISSIONS_FETCH_PERMISSIONS_FAILED = 'MANAGE_PERMISSIONS_FETCH_PERMISSIONS_FAILED'
export const MANAGE_PERMISSIONS_FETCH_PERMISSIONS_SUCCEEDED = 'MANAGE_PERMISSIONS_FETCH_PERMISSIONS_SUCCEEDED'
export const MANAGE_PERMISSIONS_TOGGLE_PERMISSION = 'MANAGE_PERMISSIONS_TOGGLE_PERMISSION '
export const MANAGE_PERMISSIONS_SAVE_REQUESTED = 'MANAGE_PERMISSIONS_SAVE_REQUESTED'
export const MANAGE_PERMISSIONS_SAVE_FAILED = 'MANAGE_PERMISSIONS_SAVE_FAILED'
export const MANAGE_PERMISSIONS_SAVE_SUCCEEDED = 'MANAGE_PERMISSIONS_SAVE_SUCCEEDED'

export type Action =
  | { type: typeof MANAGE_PERMISSIONS_REQUESTED, payload: RoleModel }
  | { type: typeof MANAGE_PERMISSIONS_CANCELED }
  | { type: typeof MANAGE_PERMISSIONS_FETCH_PERMISSIONS_FAILED, payload: Error }
  | { type: typeof MANAGE_PERMISSIONS_FETCH_PERMISSIONS_SUCCEEDED, payload: {
    allPermissions:PermissionModel[],
    rolePermissions: RolePermissionModel[]
  } }
  | { type: typeof MANAGE_PERMISSIONS_TOGGLE_PERMISSION, payload: { permissionId: string, selected: boolean } }
  | { type: typeof MANAGE_PERMISSIONS_SAVE_REQUESTED }
  | { type: typeof MANAGE_PERMISSIONS_SAVE_FAILED, payload: Error }
  | { type: typeof MANAGE_PERMISSIONS_SAVE_SUCCEEDED, payload: Notification }

export const managePermissionsRequested = (role: RoleModel): Action => ({
  type: MANAGE_PERMISSIONS_REQUESTED,
  payload: role
})

export const managePermissionsCanceled = (): Action => ({
  type: MANAGE_PERMISSIONS_CANCELED
})

export const managePermissionsFetchPermissionsFailed = (error: Error): Action => ({
  type: MANAGE_PERMISSIONS_FETCH_PERMISSIONS_FAILED,
  payload: error
})

export const managePermissionsFetchPermissionsSucceeded = (permissions: [PermissionModel[], RolePermissionModel[]]): Action => ({
  type: MANAGE_PERMISSIONS_FETCH_PERMISSIONS_SUCCEEDED,
  payload: {
    allPermissions: permissions[0],
    rolePermissions: permissions[1]
  }
})

export const managePermissionsTogglePermission = (permissionId: string, selected: boolean): Action => ({
  type: MANAGE_PERMISSIONS_TOGGLE_PERMISSION,
  payload: {
    permissionId,
    selected
  }
})

export const managePermissionsSaveRequested = (): Action => ({
  type: MANAGE_PERMISSIONS_SAVE_REQUESTED
})

export const managePermissionsSaveFailed = (error: Error): Action => ({
  type: MANAGE_PERMISSIONS_SAVE_FAILED,
  payload: error
})

export const managePermissionsSaveSucceeded = (notification: Notification): Action => ({
  type: MANAGE_PERMISSIONS_SAVE_SUCCEEDED,
  payload: notification
})

// REDUCER

export const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case MANAGE_PERMISSIONS_REQUESTED:
      return {
        type: ManagePermissionsType.InProgress,
        role: action.payload,
        allPermissions: remoteData.loading(),
        form: {
          permissionIds: initValid([])
        },
        save: remoteData.notAsked()
      }

    case MANAGE_PERMISSIONS_CANCELED:
      return {
        type: ManagePermissionsType.NotStarted
      }

    case MANAGE_PERMISSIONS_FETCH_PERMISSIONS_FAILED:
      if (state.type !== ManagePermissionsType.InProgress) return state
      return {
        ...state,
        allPermissions: remoteData.failed(action.payload)
      }

    case MANAGE_PERMISSIONS_FETCH_PERMISSIONS_SUCCEEDED:
      if (state.type !== ManagePermissionsType.InProgress) return state
      return {
        ...state,
        allPermissions: remoteData.succeeded(action.payload.allPermissions),
        form: {
          permissionIds: initValid(action.payload.rolePermissions.map(rolePermission => rolePermission.permissionId))
        }
      }

    case MANAGE_PERMISSIONS_TOGGLE_PERMISSION:
      return updateForm(state, form => ({
        ...form,
        permissionIds: action.payload.selected
          ? toValid(
            form.permissionIds,
            [...form.permissionIds.value, action.payload.permissionId]
          )
          : toValid(
            form.permissionIds,
            form.permissionIds.value.filter(permissionId => permissionId !== action.payload.permissionId)
          )
      }))

    case MANAGE_PERMISSIONS_SAVE_REQUESTED:
      return updateSave(state, () => remoteData.loading())

    case MANAGE_PERMISSIONS_SAVE_FAILED:
      return updateSave(state, () => remoteData.failed(action.payload))

    case MANAGE_PERMISSIONS_SAVE_SUCCEEDED:
      return {
        type: ManagePermissionsType.NotStarted
      }

    default:
      return state
  }
}

const updateForm = (
  state: State,
  update: (form: Form<SetRolePermissionsModel>) => Form<SetRolePermissionsModel>
): State => {
  switch (state.type) {
    case ManagePermissionsType.InProgress:
      return {
        ...state,
        form: update(state.form)
      }

    default:
      return state
  }
}

const updateSave = (
  state: State,
  update: (save: RemoteData<Error, {}>) => RemoteData<Error, {}>
): State => {
  switch (state.type) {
    case ManagePermissionsType.InProgress:
      return {
        ...state,
        save: update(state.save)
      }

    default:
      return state
  }
}
