import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { StoreContext, deletionCanceled, deletionConfirmed, RoleDeletionType, RoleDeletion, deletionFailed, deletionSucceeded } from './store'
import { remove as removeRole } from '../../api/role'
import Translate, { translateString } from '../../components/translate'
import Typography from '@mui/material/Typography'
import ErrorMessage from '../../components/error-message'
import Box from '@mui/material/Box'
import TranslationsContext from '../../contexts/translations'
import CircularProgress from '@mui/material/CircularProgress'
import SessionContext from '../../contexts/session'
import { useTheme } from '@mui/material/styles'

const DeleteConfirmation: React.FC = () => {
  const { state, dispatch } = React.useContext(StoreContext)
  const session = React.useContext(SessionContext)
  const translations = React.useContext(TranslationsContext)
  const theme = useTheme()

  if (state.roleDeletion.type === RoleDeletionType.NotStarted) return null

  const { role } = state.roleDeletion

  const handleDelete = async () => {
    dispatch(deletionConfirmed())

    try {
      await removeRole(session.token, role.name)
      dispatch(deletionSucceeded({
        id: Math.random(),
        message: translateString(translations, 'DELETE_ROLE_SUCCESS')
      }))
    } catch (error) {
      dispatch(deletionFailed(error))
    }
  }

  return (
    <Dialog
      data-testid='delete-confirmation'
      open={isOpen(state.roleDeletion)}
      aria-labelledby='delete-confirmation-title'
      aria-describedby='delete-confirmation-description'
      onClose={() => dispatch(deletionCanceled())}
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogTitle
        id='delete-confirmation-title'
        sx={{ padding: theme.spacing(4, 4, 1) }}
      >
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <div>
            <Typography color='error' component='span' variant='h6'>
              <Translate>DELETE</Translate>:
            </Typography> {role.name}
          </div>
          {state.roleDeletion.type === RoleDeletionType.Deleting && (
            <CircularProgress size={24} />
          )}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: theme.spacing(0, 4) }}>
        <DialogContentText id='delete-confirmation-description' sx={{ marginBottom: 0 }}>
          <Translate>DELETE_ROLE_CONFIRMATION</Translate>
        </DialogContentText>
        {state.roleDeletion.type === RoleDeletionType.Failed && (
          <Box mt={2}>
            <ErrorMessage>
              <Translate>DELETE_GENERIC_ERROR</Translate>
            </ErrorMessage>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: theme.spacing(4) }}>
        <Button
          disabled={state.roleDeletion.type === RoleDeletionType.Deleting}
          onClick={() => dispatch(deletionCanceled())}
        >
          <Translate>CANCEL</Translate>
        </Button>
        <Button
          data-testid='confirm'
          variant='outlined'
          disabled={state.roleDeletion.type === RoleDeletionType.Deleting}
          onClick={handleDelete}
        >
          <Translate>DELETE_ROLE</Translate>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteConfirmation

const isOpen = (roleDeletion: RoleDeletion): boolean => {
  switch (roleDeletion.type) {
    case RoleDeletionType.NotStarted:
    case RoleDeletionType.Deleted:
      return false

    default:
      return true
  }
}
