import React from 'react'
import * as oidc from 'oidc-client'
import * as url from '../shared/url'
import { AuthSettings } from '../api/settings'

const config: oidc.UserManagerSettings = {
  redirect_uri: `${location.origin}${url.callback}`,
  response_type: 'code',
  post_logout_redirect_uri: location.origin,
  automaticSilentRenew: true,
  silent_redirect_uri: `${location.origin}${url.silentRenew}`
}

export type User = {
  // eslint-disable-next-line camelcase
  access_token: string
  expired: boolean
}

export type UserManager = {
  instance: {
    getUser: () => Promise<null | User>
    signinRedirect: () => Promise<unknown>
    signinRedirectCallback: () => Promise<unknown>
    signinSilentCallback: () => Promise<unknown>
    signoutRedirect: () => Promise<unknown>
    events: {
      addUserLoaded: (callback: (user: User) => void) => void
    }
  }
  enableLocalLogin: boolean
}

export const createUserManager = (authSettings: AuthSettings): UserManager => {
  const userManager = new oidc.UserManager({
    ...config,
    authority: authSettings.authority,
    client_id: authSettings.clientId,
    scope: authSettings.scope
  })

  userManager.events.addUserSignedOut(async () => {
    try {
      await userManager.removeUser()
    } catch (error) {
      console.warn(error)
    } finally {
      location.reload()
    }
  })

  return {
    instance: userManager,
    enableLocalLogin: authSettings.enableLocalLogin
  }
}

const notInitialized = async () => {
  throw new Error('UserManagerContext is not initialized')
}

export default React.createContext<UserManager>({
  instance: {
    getUser: notInitialized,
    signinRedirect: notInitialized,
    signinRedirectCallback: notInitialized,
    signinSilentCallback: notInitialized,
    signoutRedirect: notInitialized,
    events: {
      addUserLoaded: notInitialized
    }
  },
  enableLocalLogin: false
})
